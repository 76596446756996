<template>
  <el-collapse>
    <el-collapse-item title="Tags" name="tags">
      <el-row style="margin-bottom: 0">
        <el-col>
          <el-dialog
            title="Select pre-defined tags"
            :visible="showAdvancedDialog && predefinedTags.length > 0 && isAdvancedMode"
            :before-close="handleDialogClose"
            :destroy-on-close="true"
          >
            <el-row>
              <el-input placeholder="Search pre-defined tag" v-model="filterText">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-row>
            <el-row>
              <el-collapse v-model="activeTagCollapseIdx" accordion>
                <el-collapse-item
                  :title="tag.label | capitalize"
                  :name="tagIdx"
                  v-for="(tag, tagIdx) in predefinedTags"
                  :key="tag.value"
                >
                  <div>
                    <div class="adv-tag">
                      <div
                        class="adv-tag-col"
                        v-for="tagChild in tag.children"
                        :key="tagChild.value"
                      >
                        {{ tagChild.label }}
                        <el-tree
                          :ref="`tagTreeRefs_${tagIdx}`"
                          :data="tagChild.children"
                          show-checkbox
                          :node-key="tagChild.value"
                          :props="defaultProps"
                          @check-change="checkChange"
                          :filter-node-method="filterNode"
                        >
                        </el-tree>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-row>
            <el-row>
              <el-form label-width="120px">
                <el-form-item label="Free Text">
                  <el-col :span="12">
                    <el-input
                      placeholder="Enter free text"
                      style="width: 100%"
                      v-model="freeText"
                      @change="checkChange"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="Selected tags" v-if="isShowTagResult">
                  <el-col :span="12">
                    <el-input
                      placeholder="Enter free text"
                      style="width: 100%"
                      :readonly="true"
                      v-model="tagResult"
                      value="Claims>Plan Type>HSG Essential"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onCreateAdvancedTag">Create</el-button>
                  <el-button @click="handleDialogClose">Cancel</el-button>
                </el-form-item>
              </el-form>
            </el-row>
          </el-dialog>
          <el-row
            type="flex"
            align="middle"
            v-if="
              showTagInput && showPredefinedCascader && predefinedTags.length && !isAdvancedMode
            "
            style="margin-bottom: 10px"
          >
            <el-col :span="4" :lg="2">
              <span style="font-size: 12px">ADD TAG</span>
            </el-col>
            <el-col :span="16">
              <el-cascader
                v-model="tagInput"
                :options="predefinedTags"
                ref="saveTagInput"
                size="mini"
                style="margin-left: 5px; width: 100%"
                filterable
              >
              </el-cascader>
            </el-col>
            <el-col :span="4">
              <el-button type="info" size="mini" style="margin-left: 10px" plain @click="addTag()">
                <i class="el-icon-check"></i>
              </el-button>
              <el-button type="text" plain @click="showPredefinedCascader = false">
                <i class="el-icon-edit"></i>
              </el-button>
            </el-col>
          </el-row>
          <el-row v-else-if="showTagInput" type="flex" align="middle" style="margin-bottom: 10px">
            <el-col :span="4" :lg="2">
              <span style="font-size: 12px">ADD TAG</span>
            </el-col>
            <el-col :span="16">
              <el-input
                v-model="tagInput"
                ref="saveTagInput"
                class="input-new-tag"
                size="mini"
                style="margin-left: 5px; width: 100%"
                maxlength="50"
                @keyup.enter.native="addTag()"
              >
              </el-input>
            </el-col>
            <el-col :span="4" v-if="!isAdvancedMode">
              <el-button type="info" size="mini" style="margin-left: 10px" plain @click="addTag()">
                <i class="el-icon-check"></i>
              </el-button>
              <el-button
                v-if="predefinedTags.length"
                type="text"
                plain
                @click="showPredefinedCascader = true"
                style="margin-left: 10px"
              >
                <i class="el-icon-s-operation"></i>
              </el-button>
            </el-col>
            <el-col :span="4" v-if="isAdvancedMode">
              <el-button type="info" size="mini" style="margin-left: 10px" plain @click="addTag()">
                <i class="el-icon-check"></i>
              </el-button>
              <el-button
                size="mini"
                type="primary"
                plain
                v-if="predefinedTags.length"
                @click="openAdvancedMode"
                style="margin-left: 10px"
              >
                <i class="el-icon-s-operation"></i>
              </el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col>
          <el-tag
            v-for="(tag, index) in selectedChat.tags"
            :key="index"
            size="small"
            style="margin: 0 5px 5px 0"
            :closable="isDeletableTag(tag)"
            :disable-transitions="false"
            @close="removeTag(tag)"
            >{{ tag }}</el-tag
          >
          <el-tag
            :key="stateVariableKey"
            :disable-transitions="false"
            v-for="(stateVariableValue, stateVariableKey) in stateVariables"
            size="small"
            style="margin: 0 5px 5px 0"
          >
            {{ getTagValue(stateVariableKey, stateVariableValue) }}
          </el-tag>

          <el-tag
            v-if="isQueueTab || isResolvedTab"
            size="small"
            style="margin: 0 10px 5px 0; cursor: pointer"
            type="info"
            @click="showInput"
          >
            Add Tag
            <i class="el-icon-plus el-icon-right" />
          </el-tag>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandler";
import * as uuid from "uuid";
import { search as jmesSearch } from "jmespath-plus";

export default {
  props: ["selectedChat", "isQueueTab", "isResolvedTab", "stateVariables"],
  mixins: [errorHandlerMixin],
  data() {
    return {
      freeText: "",
      tagResult: "",
      tagObject: [],
      filterText: "",
      tagInput: "",
      showTagInput: false,
      showPredefinedCascader: false,
      showAdvancedDialog: false,
      activeTagCollapseIdx: 0,
      tagTreeRefs: [],
      defaultProps: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs[`tagTreeRefs_${this.activeTagCollapseIdx}`].map((node, nodeIdx) => {
        node.filter(val);
      });
    },
  },
  mounted() {
    if (this.predefinedTags.length) {
      this.showPredefinedCascader = true;
    }
  },
  computed: {
    isAdvancedMode() {
      const predefinedTagsOptions = _.get(
        this,
        "$store.state.modules.handover.predefinedTags",
        null
      );
      return predefinedTagsOptions?.advanced_mode;
    },
    isShowTagResult() {
      const predefinedTagsOptions = _.get(
        this,
        "$store.state.modules.handover.predefinedTags",
        null
      );
      return predefinedTagsOptions?.show_tag_result;
    },
    predefinedTags() {
      const predefinedTagsOptions = _.get(
        this,
        "$store.state.modules.handover.predefinedTags",
        null
      );

      if (!predefinedTagsOptions?.enabled) {
        return [];
      }

      const res = _.get(this, "$store.state.modules.handover.predefinedTags.tags", []);

      if (res.length && predefinedTagsOptions?.validateDepartment?.enabled) {
        const disabledRes = this.validateDepartment(res);
        return disabledRes.filter((tag) => {
          return !tag.disabled;
        });
      }

      return res;
    },
    predefinedTagsLevel() {
      const level = _.get(
        this,
        "$store.state.modules.handover.predefinedTags.validateDepartment.level",
        1
      );
      return level;
    },
    departments() {
      return this.$store.getters.userDepartment;
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return `${data.label} ${data.value}`.toLowerCase().indexOf(value.toLowerCase()) > -1;
    },
    async onCreateAdvancedTag() {
      await this.$store
        .dispatch("CREATE_TAG", {
          newTag: this.tagResult,
          chat: this.selectedChat,
        })
        .then(() => {
          if (!this.isQueueTab) {
            const tags = this.selectedChat.tags;
            const tagDeletedIdx = tags.indexOf(this.tagResult);
            if (tagDeletedIdx === -1) {
              this.selectedChat.tags.push(this.tagResult);
            }
          }
          this.showAdvancedDialog = false;
          this.showTagInput = false;

          this.$notify.success({
            title: "Success",
            message: "Successfully created tag",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: this.graphQLError(error, "Encountered error adding tag"),
            position: "bottom-right",
          });
        });
    },
    openAdvancedMode() {
      this.showAdvancedDialog = true;
      this.showTagInput = false;
    },
    handleDialogClose() {
      this.showAdvancedDialog = false;
      this.showTagInput = false;
    },
    resetAdvancedVariable() {
      this.$nextTick(() => {
        if (this.$refs[`tagTreeRefs_${this.activeTagCollapseIdx}`]) {
          this.$refs[`tagTreeRefs_${this.activeTagCollapseIdx}`].map((node, nodeIdx) => {
            node.setCheckedKeys([]);
          });
        }
        this.freeText = "";
      });

      this.checkChange();
    },
    checkChange() {
      this.tagObject = [];
      if (this.$refs[`tagTreeRefs_${this.activeTagCollapseIdx}`]) {
        this.$refs[`tagTreeRefs_${this.activeTagCollapseIdx}`].map((node, nodeIdx) => {
          const checkedKey = this.predefinedTags[this.activeTagCollapseIdx].children[nodeIdx].value;
          //Value2 is for special request from AIA, they want the seperate tag data for analytics purpose
          const checkedKey2 =
            this.predefinedTags[this.activeTagCollapseIdx].children[nodeIdx].value2;
          const checkedNode = node.getCheckedNodes();
          if (!_.isEmpty(checkedNode)) {
            const checkedNodeValues = checkedNode.map((node) => {
              return node.value;
            });
            const checkedNodeValues2 = checkedNode
              .filter((node) => {
                return node.value2;
              })
              .map((node) => {
                return node.value2;
              });
            this.tagObject.push({
              key: checkedKey,
              value: checkedNodeValues,
            });
            if (checkedKey2 && checkedNodeValues2.length > 0) {
              this.tagObject.push({
                key: checkedKey2,
                value: checkedNodeValues2,
              });
            }
          }
        });
      }

      if (this.freeText) {
        this.tagObject.push({
          key: "freeText",
          value: [this.freeText],
        });
      }
      this.tagResult = jmesSearch(this.tagObject, `[][key, join(',', value)] | [*].join(':', @)`);
    },
    getTagValue(stateVariableKey, stateVariableValue) {
      if (stateVariableKey === "department" && uuid.validate(stateVariableValue)) {
        const department = this.departmentById(stateVariableValue);
        return `${stateVariableKey}:${department?.name || stateVariableValue}`;
      }

      // if we have userQueryTopic@@history, use the value in the history log
      if (stateVariableKey === "userQueryTopic") {
        const userQueryTopicHistory = _.get(
          this.selectedChat,
          "stateVariables.userQueryTopic@@history",
          []
        );
        const userQueryTopic = userQueryTopicHistory.find(
          (it) =>
            it?.meta?.livechat?.RowKey === this.selectedChat.RowKey &&
            it?.meta?.livechat?.PartitionKey === this.selectedChat.PartitionKey
        );
        if (userQueryTopic?.value) {
          return `${stateVariableKey}:${userQueryTopic.value}`;
        }
      }

      return `${stateVariableKey}:${stateVariableValue}`;
    },
    departmentById(departmentId) {
      return this.$store.state.department.departments.find(
        (department) => department.id === departmentId
      );
    },
    async addTag() {
      const delimiter = _.get(this, "$store.state.modules.handover.predefinedTags.delimiter", ">");
      const newTag =
        this.predefinedTags.length &&
        this.showPredefinedCascader &&
        this.tagInput.length &&
        !this.isAdvancedMode
          ? this.tagInput.join(delimiter)
          : this.tagInput.trim();
      this.showTagInput = false;

      const livechatTags = _.get(this.selectedChat, "tags", []);

      if (livechatTags.indexOf(newTag) > -1) {
        this.$notify.warning({
          title: "Warning",
          message: "This chat already has this tag",
          position: "bottom-right",
        });
        return;
      }

      if (livechatTags.length >= 50) {
        this.$notify.warning({
          title: "Warning",
          message: "This chat already has 50 tags",
          position: "bottom-right",
        });
        return;
      }

      if (newTag.length === 0) return;
      this.tagInput = "";

      await this.$store
        .dispatch("CREATE_TAG", {
          newTag,
          chat: this.selectedChat,
        })
        .then(() => {
          if (!this.isQueueTab) {
            const tags = this.selectedChat.tags;
            const tagCreatedIdx = tags.indexOf(newTag);
            if (tagCreatedIdx === -1) {
              this.selectedChat.tags.push(newTag);
            }
          }
          this.$notify.success({
            title: "Success",
            message: "Successfully created tag",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: this.graphQLError(error, "Encountered error adding tag"),
            position: "bottom-right",
          });
        });
    },

    async removeTag(tagToDelete) {
      await this.$store
        .dispatch("DELETE_TAG", {
          tagToDelete,
          chat: this.selectedChat,
        })
        .then(() => {
          const tags = this.selectedChat.tags;
          const tagDeletedIdx = tags.indexOf(tagToDelete);
          if (tagDeletedIdx > -1) {
            this.selectedChat.tags.splice(tagDeletedIdx, 1);
          }

          this.$notify.success({
            title: "Success",

            message: "Successfully removed tag",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: "Encountered error removing tag",
            position: "bottom-right",
          });
        });
    },

    showInput() {
      this.showTagInput = true;
      this.$nextTick(() => {
        if (!this.predefinedTags.length) {
          this.$refs.saveTagInput.focus();
        }
      });
    },

    validateDepartment(tags, loop = 1) {
      const resultArr = [];

      for (const tag of tags) {
        const resultObj = _.cloneDeep(tag);
        if (loop === this.predefinedTagsLevel) {
          resultObj.disabled = true;
        } else {
          resultObj.disabled = false;
        }
        if (tag.departmentEnabledRegex) {
          try {
            const regex = new RegExp(tag.departmentEnabledRegex);

            for (let i = 0; i < this.departments.length; i++) {
              let department = this.departments[i];
              if (regex.test(department)) {
                resultObj.disabled = false;
                break;
              }
            }
          } catch (e) {
            console.log("Error in pre-defined tag department regex: " + e);
          }
        } else if (
          _.includes(this.departments, tag.value.toLowerCase()) &&
          loop === this.predefinedTagsLevel
        ) {
          resultObj.disabled = false;
        }
        if (tag.children) {
          resultObj.children = this.validateDepartment(tag.children, loop + 1);
        }
        resultArr.push(resultObj);
      }
      return resultArr;
    },

    isDeletableTag(textTag) {
      if (textTag.indexOf(":") === -1) {
        return true;
      }

      const key = textTag.split(":")[0];

      const coreSystemTags = ["appSource", "channel", "PartitionKey", "RowKey"];
      const extraSystemTagKeys = _.get(
        this,
        "$store.state.modules.handover.systemTags.extraSystemTagKeys",
        ""
      )
        .replace(/\s/g, "")
        .split(",");

      const systemTagKeys = coreSystemTags.concat(extraSystemTagKeys);

      return systemTagKeys.indexOf(key) === -1;
    },
  },
};
</script>
<style scoped>
.adv-tag {
  align-content: flex-start;
  display: flex;
  overflow: auto;
}
.adv-tag-col {
  width: 30%;
  height: 100%;
  display: inline-block;
}
</style>
