<template>
  <div>
    <APIEditor ref="api-editor" />
  </div>
</template>
<script>
import APIEditor from "@/components/APIEditor/Index.vue";

export default {
  components: { APIEditor },
  mounted() {
    this.$store.dispatch("FETCH_ALL_MAPPINGS", {
      brain: this.$store.state.brain,
    });
    this.$store.dispatch("FETCH_ALL_APIS", {
      brain: this.$store.state.brain,
    });
  },
};
</script>
