<template>
  <el-card class="main-flow-editor-window">
    <!-- Flow module header -->
    <div slot="header" class="clear-fix">
      <FlowEditorHeader
        ref="flowEditorHeader"
        :selected-flow="selectedFlow"
        :flows="flows"
        :array-flows="arrayFlows"
        :parent-is-busy="isBusy"
        @setSelectedFlow="selectedFlow = typeof $event === 'string' ? flows[$event] : $event"
      />
    </div>

    <template v-if="isFetchingFlows">
      <div style="text-align: center">
        <p style="text-align: center" class="has-text-dark">
          {{ busyDescription || "Fetching sections..." }}
        </p>
        <i style="font-size: 32px" class="has-text-primary el-icon-loading"></i>
      </div>
    </template>

    <template v-else-if="arrayFlows.length === 0">
      <p style="text-align: center; padding: 20px" class="has-text-dark">
        <i>No flow added...</i>
      </p>
    </template>

    <template v-else>
      <section v-if="selectedFlow">
        <el-row
          type="flex"
          :gutter="0"
          justify="space-between"
          class="flow-title"
          style="margin-bottom: 0"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              margin-bottom: 0;
            "
          >
            <el-input style="min-width: 500px" :disabled="isBusy" v-model="selectedFlow.title">
              <div slot="prepend">Flow Title</div>
            </el-input>
          </div>

          <div style="display: flex; flex-direction: row; align-items: center; padding: 5px">
            <el-button
              plain
              style="margin-left: 15px"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              :disabled="isBusy"
              @click.prevent="addSection"
              >Add Section</el-button
            >
            <el-button
              plain
              type="warning"
              size="mini"
              icon="el-icon-delete"
              :disabled="isBusy"
              @click.prevent="onDeleteFlow(selectedFlow.flowId)"
              >Delete Flow</el-button
            >

            <!-- <el-popover
              placement="bottom"
              title="Duplicate Flow Name"
              width="300"
              trigger="click"
              v-model="isDuplicateFlowOpened"
            >
              <div>
                <el-input
                  v-model="temporaryFlowName"
                  placeholder="Please input flow name..."
                  style="margin-bottom: 20px;"
                >
                  <template slot="append">
                    <el-button
                      id="webpage-add-button"
                      type="primary"
                      size="small"
                      @click.prevent="onDuplicateFlow"
                    >
                      <i class="el-icon-check"></i>
                    </el-button>
                  </template>
                </el-input>
              </div>
              <el-button
                plain
                type="warning"
                icon="el-icon-document-copy"
                style="margin: 0 10px;"
                :disabled="isBusy"
                size="medium"
                slot="reference"
              >Duplicate Flow</el-button>
            </el-popover>-->
            <el-button
              plain
              type="success"
              size="mini"
              icon="el-icon-check"
              :disabled="isBusy"
              @click.prevent="onSave(selectedFlow.flowId)"
              >Save Flow</el-button
            >
            <el-button
              plain
              icon="el-icon-chat-line-round"
              type="info"
              size="mini"
              :disabled="isBusy"
              @click.prevent="onPreview"
              >Preview</el-button
            >
          </div>
        </el-row>

        <br />

        <template v-if="isBusy">
          <div style="text-align: center; padding: 20px" class="has-text-dark">
            <p>{{ isBusyDescription }}</p>
            <i style="font-size: 32px" class="has-text-primary el-icon-loading"></i>
          </div>
        </template>
        <template v-else>
          <FlowEditorView
            ref="flow-editor-view"
            :resetDescription="resetDescription"
            :resetOptions="resetOptions"
            :cancelOptions="cancelOptions"
            :allFlowsNameArray="allFlowsNameArray"
            :active-flow="selectedFlow"
            :array-flows="arrayFlows"
          />
        </template>
      </section>

      <FlowEditorPreview ref="flow-editor-preview" :active-flow="selectedFlow" />
    </template>
  </el-card>
</template>
<script>
import _ from "lodash";
import { checkFlowValidity } from "./util";

import FlowEditorHeader from "./Header/Index";
import FlowEditorView from "./View/Index";
import FlowEditorPreview from "./Preview/Index";

export default {
  components: {
    // JSONEditor,
    FlowEditorHeader,
    FlowEditorView,
    FlowEditorPreview,
  },
  props: ["editorOption"],
  data() {
    return {
      selectedFlow: null,
      isBusy: false,
      busyDescription: null,
      isDuplicateFlowOpened: false,
      temporaryFlowName: null,
      temporaryQuestion: null,
    };
  },
  mounted() {
    this.isBusyDescription = "Fetching all flow...";
    this.$store
      .dispatch("FETCH_FLOWS")
      .then((flows) => {
        const totalFlows = _.keys(flows);
        if (!_.isEmpty(totalFlows)) {
          const firstFlow = flows[totalFlows[0]];
          this.$refs.flowEditorHeader.filtered = firstFlow.flowId;
          this.selectedFlow = firstFlow;
        }
      })
      .catch(() => {
        this.$notify({
          type: "error",
          title: "Error",
          message: "Encountered error fetching flows",
          position: "bottom-right",
        });
      })
      .finally(() => {
        this.isBusyDescription = null;
      });

    this.$eventBus.$on("moveQuestion", (question) => {
      if (!this.isBusy) {
        this.temporaryQuestion = question;
        const callback = () => {
          const sectionIndex = this.flows[this.temporaryQuestion.flowId].sections.findIndex(
            (section) => section.sectionId === this.temporaryQuestion.sectionId
          );

          this.flows[this.temporaryQuestion.flowId].sections[sectionIndex].questions.push(
            this.temporaryQuestion
          );

          this.selectedFlow = this.flows[this.temporaryQuestion.flowId];

          this.$nextTick(() => {
            this.$refs[
              "flow-editor-view"
            ].currentTab = `section-${this.temporaryQuestion.sectionId}`;

            this.$eventBus.$emit("setHeaderFilter", [
              this.temporaryQuestion.flowId,
              this.temporaryQuestion.sectionId,
            ]);

            this.$notify({
              type: "success",
              title: "Success",
              message: `Question moved, please press save to continue.`,
              position: "bottom-right",
            });

            this.temporaryQuestion = null;
          });
        };
        if (this.temporaryQuestion.flowId === this.selectedFlow.flowId) {
          callback();
        } else {
          this.saveFlow(callback);
        }
      }
    });

    this.$eventBus.$on("duplicateSection", (event) => {
      if (!this.isBusy) {
        this.flows[event.section.flowId].sections.push(event.section);
        if (event.navigateAway) {
          this.selectedFlow = this.flows[event.section.flowId];
          this.$nextTick(() => {
            this.$refs["flow-editor-view"].currentTab = `section-${event.section.sectionId}`;

            this.$eventBus.$emit("setHeaderFilter", [
              event.section.flowId,
              event.section.sectionId,
            ]);
          });
        }
      }
    });
  },
  computed: {
    /**
     * @description Is fetching flow, from state
     * @return {boolean}
     */
    isFetchingFlows() {
      return this.$store.getters.isFetchingFlows;
    },

    resetDescription() {
      let result = "";
      const defaultText =
        "Which flow and section to reset workflow to when the keywords are triggered.";
      const resetKeywords = _.get(this, "$store.state.modules.floweditor.LIST_OF_RESET_KEYWORDS");
      if (resetKeywords) {
        result += defaultText;
        result += "\n";
        result += `Current keywords: ${resetKeywords.replace(/;/g, " | ")}`;
      }
      return result;
    },

    resetOptions() {
      const allSections = this.allFlowsNameArray;

      const defaultOption = {
        value: "current_section",
        label: "Question's current section",
      };
      _.findIndex(allSections, { value: "current_section" }) === -1 &&
        allSections.push(defaultOption);

      const standardOption = {
        value: "previous_question",
        label: "Previous question",
      };
      _.findIndex(allSections, { value: "previous_question" }) === -1 &&
        allSections.push(standardOption);

      return allSections;
    },

    cancelOptions() {
      const cancelKeywordsString = _.get(
        this,
        "$store.state.modules.floweditor.LIST_OF_CANCEL_KEYWORDS"
      );
      if (cancelKeywordsString && typeof cancelKeywordsString === "string") {
        return cancelKeywordsString.trim().split(";");
      } else {
        return [];
      }
    },

    flows() {
      return _.get(this.$store, "state.floweditor.flows", {});
    },

    arrayFlows() {
      return _.chain(this.flows).values().orderBy(["index"], ["asc"]).value();
    },

    allFlowsNameArray() {
      const allFlows = Object.values(this.flows);
      return _.chain(allFlows)
        .filter(({ flowType }) => {
          return flowType.toLowerCase() === "standard";
        })
        .map((flow) => {
          return flow.sections;
        })
        .flatten()
        .map(({ sectionId, title, flowId }) => {
          const flow = this.flows[flowId] || {};
          return {
            value: sectionId,
            label: `${title} (${flow.title})`,
          };
        })
        .value();
    },
  },
  methods: {
    /**
     * @description On show preview drawer
     * @return {void}
     */
    onPreview() {
      this.$refs["flow-editor-preview"].isPreviewOpen = true;
    },

    /**
     * @description On select flow
     * @param {VueComponent} tab
     * @return {void}
     */
    onSelectFlow(id) {
      this.selectedFlow = this.flows[id];
      this.$nextTick(() => {
        this.isBusy = false;
        this.isBusyDescription = null;
      });
    },

    /**
     * @description Delete a flow confirmation
     * @return {void}
     */
    onDeleteFlow(id) {
      this.$confirm(`Are you sure to delete the flow?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => this.deleteFlow(id))
        .catch(() => {});
    },

    /**
     * @description Delete a flow
     * @return {void}
     */
    deleteFlow(id) {
      this.isBusy = true;
      this.$eventBus.$emit("setHeaderFilter", []);
      this.isBusyDescription = `Deleting flow '${this.selectedFlow.title}'...`;
      this.$store
        .dispatch("DELETE_FLOW", { flowId: id })
        .then(() => {
          const lastAvailableFlow = _.last(this.arrayFlows);
          if (lastAvailableFlow) {
            this.onSelectFlow(lastAvailableFlow.flowId);
            this.$eventBus.$emit("setHeaderFilter", [lastAvailableFlow.flowId]);
          }
        })
        .catch((err) => {
          this.$notify({
            type: "error",
            title: "Error",
            message: "Encountered error deleting flow",
            position: "bottom-right",
          });
        })
        .finally(() => {
          this.isBusy = false;
          this.isBusyDescription = null;
        });
    },

    /**
     * @description Save flow confirmation dialog
     * @return {void}
     */
    onSaveFlow() {
      this.$confirm(`Are you sure to save the selected flow?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => this.saveFlow())
        .catch(() => {});
    },

    /**
     * @description Save flow
     * @return {void}
     */
    saveFlow(callback = null) {
      this.isBusy = true;
      this.isBusyDescription = `Saving flow...`;
      this.$store
        .dispatch("SAVE_FLOW", {
          flowId: this.selectedFlow.flowId,
          flowObject: this.selectedFlow,
        })
        .then((response) => {
          if (!callback) {
            this.$notify({
              type: "success",
              title: "Success",
              message: `Flow '${this.selectedFlow.title}' updated.`,
              position: "bottom-right",
            });
          } else {
            this.isBusy = false;
            callback();
          }
        })
        .catch((err) => {
          console.log("Encountered error saving flow.", err);
          this.$notify({
            type: "error",
            title: "Error",
            message: "Encountered error saving flow.",
            position: "bottom-right",
          });
        })
        .finally(() => {
          this.isBusy = false;
          this.isBusyDescription = null;
        });
    },

    /**
     * @description On add new section to current flow
     * @return {void}
     */
    addSection() {
      this.isBusy = true;
      this.isBusyDescription = `Loading sections...`;
      this.$store
        .dispatch("CREATE_NEW_SECTION", { flow: this.selectedFlow })
        .then((newSection) => {
          this.selectedFlow.sections.push(newSection);
          this.isBusy = false;
          this.$nextTick(() => {
            this.$eventBus.$emit("setHeaderFilter", [
              this.selectedFlow.flowId,
              newSection.sectionId,
            ]);

            this.$refs["flow-editor-view"].currentTab = `section-${newSection.sectionId}`;
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "error",
            message: "Encountered error creating new section.",
          });
        })
        .finally(() => {
          this.isBusy = false;
          this.isBusyDescription = null;
        });
    },

    /**
     * @description On save flow confirmation
     * @return {void}
     */
    onSave() {
      this.$confirm(`Are you sure to save the flow?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => this.save())
        .catch(() => {});
    },

    /**
     * @description Save selected flow
     * @return {void}
     */
    save() {
      const { status: isValid, message: errorMessage } = checkFlowValidity(this.selectedFlow);

      if (!isValid) {
        this.$notify.error({
          title: "Saving Error",
          message: errorMessage,
          position: "bottom-right",
        });
        return;
      }

      this.isBusy = true;
      this.busyDescription = "Saving flow...";

      this.$store
        .dispatch("SAVE_FLOW", {
          flowId: this.selectedFlow.flowId,
          flowObject: this.selectedFlow,
        })
        .then((saved) => {
          if (saved) {
            this.$notify.success({
              type: "success",
              title: "Success",
              position: "bottom-right",
              message: "Flow saved!",
            });
          } else {
            this.$notify.error({
              type: "error",
              title: "Error",
              message: "Encountered error saving flow!",
              position: "bottom-right",
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            type: "error",
            title: "Error",
            message: "Encountered error saving flow!",
            position: "bottom-right",
          });
        })
        .finally(() => {
          this.isBusy = false;
          this.busyDescription = null;
        });
    },

    onDuplicateFlow() {
      this.$eventBus.$emit("duplicateFlow", {
        flowName: this.temporaryFlowName,
        flow: this.selectedFlow,
      });
      this.$nextTick(() => {
        this.temporaryFlowName = null;
        this.isDuplicateFlowOpened = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

.main-flow-editor-window {
  padding: 0px !important;
  margin-bottom: 0 !important;

  .flow-editor-panel {
    height: 73.6vh;
    @media screen and (max-width: 1440px) {
      height: 71vh;
    }
  }

  .flow-editor-list {
    overflow-x: hidden;
    overflow-y: auto;
    .flow-editor-list-item {
      padding: 10px;
      font-size: 14px;
      color: $color-dark;
      border: 1px solid $color-light;
      cursor: pointer;

      &.is-current {
        background-color: $color-light;
      }
    }
  }

  .flow-editor-view {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media only screen and (max-width: 1280px) {
    .flow-title {
      flex-wrap: wrap;
    }
  }
}
</style>
