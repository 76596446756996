import { render, staticRenderFns } from "./ProfileSettingsTab.vue?vue&type=template&id=4ea592e6&"
import script from "./ProfileSettingsTab.vue?vue&type=script&lang=js&"
export * from "./ProfileSettingsTab.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSettingsTab.vue?vue&type=style&index=0&id=4ea592e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports