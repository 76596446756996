<template>
  <div :class="customClasses">
    <el-button @click="toggleExpandChats" class="chat-group-down" :style="borderStyle">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <slot name="title">
          <div
            :style="{
              color: getColor(),
            }"
          >
            {{ title }}
          </div>
        </slot>
        <i :class="isExpanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </el-button>
    <el-container v-if="isExpanded" v-loading="chatsLoading" class="chats-container">
      <el-scrollbar wrap-style="max-height: 500px" v-if="chats.length > 0">
        <div class="el-scrollbar--inner">
          <el-row
            v-for="chat in chats"
            :key="chat.RowKey"
            class="chat-item"
            :class="{
              active: selectedChat && selectedChat.RowKey === chat.RowKey,
            }"
            @click.native="(e) => selectChat(e, chat)"
          >
            <el-col style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              <el-row type="flex" style="margin-bottom: 0; align-items: center">
                <ChatAvatar :chat="chat" style="display: inline-block" />
                <el-row
                  type="flex"
                  style="flex-direction: column; margin-bottom: 0; overflow: hidden"
                >
                  <span style="font-size: 16px">{{ chat | getChatName }}</span>
                  <div
                    style="text-align: left; margin-top: 3px; font-size: 0.6em; margin-right: 5px"
                  >
                    Duration: {{ getChatDuration(chat) }}
                  </div>
                </el-row>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-scrollbar>
      <div class="empty-list" v-else>There is nothing in here</div>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { printDuration } from "@/helperMethods/livechat/util";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";

export default {
  components: {
    ChatAvatar,
  },
  data() {
    return {
      isExpanded: false,
      selectedChat: null,
    };
  },
  props: {
    customClasses: String,
    type: String,
    chatsLoading: Boolean,
    chats: Array,
    expanded: Boolean,
    showBorder: { type: Boolean, default: true },
  },
  computed: {
    ...mapState("supervisor", ["selectedChatSession"]),
    borderStyle() {
      return this.showBorder && { borderLeft: `3px solid ${this.getColor()}` };
    },
    title() {
      return this.type && `${this.type.toUpperCase()} (${this.chats.length})`;
    },
  },
  methods: {
    printDuration,
    getColor() {
      switch (this.type) {
        case "in-queue":
          return "#5d9efb";
        case "on-going":
          return "#3cb52e";
        case "abandoned":
          return "#e54639";
        case "resolved":
          return "#868686";
      }
    },
    toggleExpandChats() {
      this.isExpanded = !this.isExpanded;
      this.$emit("update:expanded", this.type);
    },
    getChatDuration(chat) {
      return this.printDuration(
        chat.startQueueTime,
        chat.endResolveTime || moment().format(),
        true
      );
    },
    selectChat(e, chat) {
      this.selectedChat = chat;
    },
  },
  watch: {
    expanded(value) {
      this.isExpanded = value;
    },
    isExpanded(value) {
      this.selectedChat = null;
    },
    selectedChat(value) {
      this.$emit("update:selectedChat", value);
    },
    chats(value) {
      this.selectedChat = this.selectedChatSession
        ? _.find(value, (chat) => chat.RowKey === this.selectedChatSession.RowKey)
        : null;
    },
    selectedChatSession(chatSession) {
      this.selectedChat = chatSession
        ? _.find(this.chats, (chat) => chat.RowKey === chatSession.RowKey)
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.chat-group-down {
  padding: 12px 10px;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.chat-item {
  padding: 8px;
  border: 1px solid $color-light;
  /* line-height: 32px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $color-light;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  &:hover,
  &.active {
    background-color: rgba(64, 158, 255, 0.1);
    border-color: rgba(64, 158, 255, 0.25);
    transition: all ease-in-out 0.25s;
  }
}
.chats-container {
  padding-top: 12px;
  max-height: 500px;
  width: 100%;
}
::v-deep .el-scrollbar__wrap {
  margin-right: 0;
  overflow-x: hidden !important;
}
.el-scrollbar {
  width: 100%;
}
.el-scrollbar--inner {
  padding: 10px 15px;
}
.empty-list {
  text-align: center;
  width: 100%;
  color: $color-dark;
  font-size: 14px;
  margin: 5px 0;
}
</style>
