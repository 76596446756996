import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import gql from "graphql-tag";
import { ActionTree } from "vuex";
import { OutreachState, OUT_REACH_TAG_TYPE } from "./types";

const actions: ActionTree<OutreachState, RootState> = {
  FETCH_OUTREACH_CONTACTS: async (
    { commit },
    { take = 10, page = 1, startDate = "", endDate = "", filter, sort }
  ) => {
    commit("SET_OUTREACH_LOADING", true);
    return graph
      .query({
        query: gql`
          query (
            $take: Int!
            $page: Int!
            $startDate: String
            $endDate: String
            $filter: [JSON]
            $sort: JSON
          ) {
            outReachContactAPI {
              getAllOutReachContacts(
                take: $take
                page: $page
                startDate: $startDate
                endDate: $endDate
                filter: $filter
                sort: $sort
              )
            }
          }
        `,
        variables: {
          take,
          page,
          startDate,
          endDate,
          filter,
          sort,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        const result = data.outReachContactAPI.getAllOutReachContacts;
        commit("SET_OUTREACH_CONTACTS", result);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
  FETCH_OUTREACH_SEGMENTS: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            outReachTagStageAPI {
              getOutReachTags
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.outReachTagStageAPI.getOutReachTags;
        const segmentsTags = result.filter((tag) => tag.type === OUT_REACH_TAG_TYPE.TAG);
        const segmentsStages = result.filter((tag) => tag.type === OUT_REACH_TAG_TYPE.STAGE);
        commit("SET_OUTREACH_TAGS", segmentsTags);
        commit("SET_OUTREACH_STAGES", segmentsStages);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
  FETCH_CONTACT_ATTRIBUTES: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            outReachContactAPI {
              getAllContactAttributes
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.outReachContactAPI.getAllContactAttributes;
        commit("SET_CONTACT_ATTRIBUTES", result);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
};

export default actions;
