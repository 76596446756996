<template>
  <span style="margin-left: 10px">
    <el-button size="medium" type="text" icon="el-icon-upload2" @click="handleOpen"> </el-button>

    <el-drawer
      id="file-storage-upload-drawer"
      direction="rtl"
      :with-header="false"
      :visible.sync="isOpen"
      ref="file-storage-upload-drawer"
    >
      <div class="wrapper">
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          accept=".html, .csv, .pdf, image/*, .doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .mp4, .webm"
          :multiple="true"
          :limit="10"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="handleChange"
          :on-remove="handleRemove"
        >
          <el-button :disabled="isBusy" plain ref="uploader" size="mini" type="primary">
            Click to upload
          </el-button>
          <div slot="tip" class="el-upload__tip">
            Accepted file types html, pdf, jpg, jpeg, png, gif, csv...
            <div v-if="isBusy" v-loading="true" style="padding-top: 30px; height: 40px"></div>
          </div>
        </el-upload>

        <div>
          <el-button
            :disabled="isBusy || !fileList.length || scanningVirus"
            :loading="isBusy"
            @click="submit"
            plain
            size="mini"
            type="success"
            >Submit</el-button
          >
          <el-button :disabled="isBusy" @click="handleClose" plain size="mini" type="text">
            Cancel
          </el-button>
        </div>
      </div>
    </el-drawer>
  </span>
</template>

<script>
import { validateFileUpload } from "@/helperMethods/fileUpload";
import { mapGetters } from "vuex";

export default {
  props: ["opened"],
  computed: {
    ...mapGetters(["storageType", "isAzureStorage", "isCephStorage"]),
  },
  data() {
    return {
      isOpen: false,
      isBusy: false,
      fileList: [],
      scanningVirus: false,
    };
  },
  methods: {
    handleOpen() {
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs["uploader"].$el.click();
      });
    },
    handleClose() {
      this.isOpen = false;
      this.fileList = [];
    },
    async submit() {
      if (this.isBusy || !this.fileList.length) {
        return;
      }
      try {
        const shouldUpload = await this.$confirm(
          "This will upload selected files. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        if (shouldUpload) {
          this.upload();
        }
      } catch (error) {
        console.log("upload cancel");
      }
    },
    handleChange(file, fileList) {
      const formData = new FormData();
      formData.append("file", file.raw);
      this.fileList = fileList;

      const { validated, errorMessage } = validateFileUpload(file.raw);
      if (!validated) {
        this.$notify.error({
          title: `File ${file.name} didn't pass validation.`,
          position: "bottom-right",
          message: errorMessage,
        });
        this.fileList.splice(-1, 1);
        return;
      }

      this.scanningVirus = true;
      this.$rest("post", "miniapp_virus_scan", formData)
        .then((response) => {
          const result = _.get(response, "data", {});
          const isSafe = !result.is_infected;
          const virusDetected = !_.isEmpty(result.viruses);
          if (!isSafe || virusDetected) {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `File ${file.name} contain suspicious malware, please try with other file.`,
            });
            this.fileList.splice(-1, 1);
          }
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            title: "Warning",
            position: "bottom-right",
            message:
              "Antivirus failed to run! If you continue, this file will be upload without virus scan",
          });
        })
        .finally(() => (this.scanningVirus = false));
    },
    handleRemove(file, fileList) {
      _.remove(this.fileList, (o) => o.name === file.name);
    },
    async upload() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;
      try {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          if (_.includes(file.raw.type, "image")) {
            await this.uploadImage(file.raw);
          } else if (_.includes(file.raw.type, "html")) {
            await this.uploadHtml(file.raw);
          } else if (_.includes(file.raw.type, "video")) {
            await this.uploadVideo(file.raw);
          } else {
            await this.uploadDoc(file.raw);
          }
        }

        setTimeout(() => {
          this.handleClose();
          this.$emit("refresh");
          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: `File uploaded`,
          });
          this.isBusy = false;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.isBusy = false;
      }
    },
    async uploadHtml(file) {
      const form = new FormData();
      form.append("brain", this.$store.state.brain);
      if (this.opened) {
        form.append("name", this.opened);
      }
      form.append("single", file);
      if (this.isAzureStorage) {
        await this.$rest("post", "miniapp_upload", form);
      } else if (this.isCephStorage) {
        await this.$store.dispatch("MINI_APP_UPLOAD_DOCUMENT", form);
      }
    },
    async uploadImage(file) {
      const form = new FormData();
      form.append("brain", this.$store.state.brain);
      if (this.opened) {
        form.append("name", this.opened);
      }
      form.append("images", file);
      if (this.isAzureStorage) {
        await this.$rest("post", "miniapp_images_upload", form);
      } else if (this.isCephStorage) {
        await this.$store.dispatch("MINI_APP_UPLOAD_IMAGE", form);
      }
    },
    async uploadDoc(file) {
      const form = new FormData();
      form.append("brain", this.$store.state.brain);
      if (this.opened) {
        form.append("name", this.opened);
      }
      form.append("documents", file);

      if (this.isAzureStorage) {
        await this.$rest("post", "miniapp_documents_upload", form);
      } else if (this.isCephStorage) {
        await this.$store.dispatch("MINI_APP_UPLOAD_DOCUMENT", form);
      }
    },
    async uploadVideo(file) {
      const form = new FormData();
      form.append("brain", this.$store.state.brain);
      if (this.opened) {
        form.append("name", this.opened);
      }
      form.append("videos", file);

      if (this.isAzureStorage) {
        await this.$rest("post", "miniapp_videos_upload", form);
      } else if (this.isCephStorage) {
        this.$message({
          message: "Commint upload video with ceph soon..",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style scoped>
#file-storage-upload-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
>>> .el-upload {
  text-align: left;
}
.wrapper {
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper .upload-demo {
  flex-grow: 1;
  flex: 1;
}
</style>
