<template>
  <div>
    <el-card>
      <h3 slot="header">Assignment Queue Actions</h3>

      <div style="margin-bottom: 20px; margin-left: 5px">
        <p>
          <small>
            Actions based on assignment queue, start from 0 (0 is nearest position for handover)
          </small>
        </p>
      </div>

      <el-row :gutter="15">
        <el-col :span="8">
          <el-checkbox
            v-model="value.assignmentQueueActions.enabled"
            label="Enable"
            border
            style="margin-bottom: 15px"
          ></el-checkbox>

          <el-card shadow="always">
            <el-table :data="value.assignmentQueueActions.actions" stripe style="width: 100%">
              <el-table-column prop="queuePosition" label="Queue Position"> </el-table-column>
              <el-table-column prop="event" label="Event"> </el-table-column>
              <el-table-column prop="data" label="Data"> </el-table-column>
              <el-table-column label="Delete" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="value.assignmentQueueActions.actions.splice(scope.$index, 1)"
                  >
                    <i class="el-icon-delete"
                  /></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-divider />

            <el-popover
              v-model="isVisible"
              placement="top"
              title="Add new action"
              width="400"
              trigger="click"
              @after-leave="form = { event: 'goto', data: null, queuePosition: null }"
            >
              <div style="margin-top: 20px; margin-bottom: 15px">
                <el-radio-group @change="form.data = null" size="mini" v-model="form.event">
                  <el-radio-button label="goto"></el-radio-button>
                  <el-radio-button label="callApi"></el-radio-button>
                </el-radio-group>
              </div>

              <el-input
                type="number"
                style="width: 100%; margin-bottom: 15px"
                v-model="form.queuePosition"
                size="mini"
                placeholder="Queue Position"
              />

              <el-select
                v-model="form.data"
                filterable
                size="mini"
                style="width: 100%; margin-bottom: 15px"
              >
                <el-option v-for="item in options" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>

              <div style="text-align: right">
                <el-button @click="isVisible = false" size="mini" plain type="danger"
                  >Cancel</el-button
                >
                <el-button @click="onSave" size="mini" type="success">Save</el-button>
              </div>

              <el-button
                slot="reference"
                size="mini"
                type="primary"
                style="width: 100%; position: relative"
              >
                Add
              </el-button>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>

      <div style="margin-bottom: 20px; margin-left: 5px">
        <p>
          <small> Call an actions at specified intervals (in milliseconds). </small>
        </p>
      </div>

      <el-row :gutter="15">
        <el-col :span="8">
          <el-card shadow="always">
            <el-table
              :data="value.assignmentQueueActions.intervalActions"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="intervalTime" label="Time"> </el-table-column>
              <el-table-column prop="event" label="Event"> </el-table-column>
              <el-table-column prop="data" label="Data"> </el-table-column>
              <el-table-column label="Delete" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="value.assignmentQueueActions.intervalActions.splice(scope.$index, 1)"
                  >
                    <i class="el-icon-delete"
                  /></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-divider />

            <el-popover
              v-model="isVisibleInterval"
              placement="top"
              title="Add new interval action"
              width="400"
              trigger="click"
              @after-leave="form = { event: 'goto', data: null, queuePosition: null }"
            >
              <div style="margin-top: 20px; margin-bottom: 15px">
                <el-radio-group @change="form.data = null" size="mini" v-model="form.event">
                  <el-radio-button label="goto"></el-radio-button>
                  <el-radio-button label="callApi"></el-radio-button>
                </el-radio-group>
              </div>

              <el-input
                type="number"
                style="width: 100%; margin-bottom: 15px"
                v-model="form.intervalTime"
                size="mini"
                placeholder="Interval time"
              />

              <el-select
                v-model="form.data"
                filterable
                size="mini"
                style="width: 100%; margin-bottom: 15px"
              >
                <el-option v-for="item in options" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>

              <div style="text-align: right">
                <el-button @click="isVisible = false" size="mini" plain type="danger"
                  >Cancel</el-button
                >
                <el-button @click="onSaveIntervalAction" size="mini" type="success">Save</el-button>
              </div>

              <el-button
                slot="reference"
                size="mini"
                type="primary"
                style="width: 100%; position: relative"
              >
                Add
              </el-button>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "assignmentQueueActions",
  props: ["setting", "value", "modules"],
  data() {
    return {
      isVisible: false,
      form: {
        event: "goto",
        data: null,
        queuePosition: "",
      },
    };
  },
  computed: {
    options() {
      return this.form.event === "goto"
        ? Object.keys(this.$store.state.nodes.content)
        : Object.keys(this.$store.state.apieditor.mappings);
    },
  },
  methods: {
    onSave() {
      this.value.assignmentQueueActions.actions.push(this.form);
      this.isVisible = false;
    },
    onSaveIntervalAction() {
      this.value.assignmentQueueActions.intervalActions.push(this.form);
      this.isVisibleInterval = false;
    },
  },
  beforeMount() {
    if (!this.value.assignmentQueueActions || !this.value.assignmentQueueActions.actions) {
      this.$set(this.value, "assignmentQueueActions", {
        enabled: false,
        actions: [],
        intervalActions: [],
      });
    }
  },
};
</script>
