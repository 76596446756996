import { AccessControlState } from "./types";

const state: AccessControlState = {
  auth0: {
    users: [],
    usersStatic: [],
    user: {},
  },
};

export default state;
