<template>
  <el-row style="height: 100%" v-loading="isLoading && activeName !== 'campaign'">
    <div class="out-reach">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Contacts" name="contacts"><TabContacts /></el-tab-pane>
        <el-tab-pane
          label="Segments"
          name="segments"
          v-if="checkRoles(getPermittedRoles('outreach-admin'))"
        >
          <TabSegments />
        </el-tab-pane>
        <el-tab-pane
          label="Campaign"
          name="campaign"
          v-if="checkRoles(getPermittedRoles('outreach-admin'))"
        >
          <TabCampaign />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-row>
</template>

<script>
import TabContacts from "./TabContacts/Index.vue";
import TabSegments from "./TabSegments/Index.vue";
import TabCampaign from "./TabCampaign/Index.vue";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { OUT_REACH_MODULE } from "@/helperMethods/outreach";
export default {
  components: { TabContacts, TabSegments, TabCampaign },
  data() {
    return {
      activeName: "contacts",
      OUT_REACH_MODULE,
    };
  },
  created() {
    const mainTag = document.getElementsByTagName("main");
    mainTag[0].style.padding = 0;
  },
  destroyed() {
    const mainTag = document.getElementsByTagName("main");
    mainTag[0].style.padding = "10px";
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.outreach.isLoading;
    },
  },
  mounted() {
    this.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
    this.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
  },
};
</script>

<style lang="scss">
@import url("https://fonts.cdnfonts.com/css/avenir");

.out-reach {
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  margin-left: 15px;
  height: 100%;
  > .el-tabs {
    padding: 5px;
    > .el-tabs__header {
      padding: 0 5px !important;
      padding-bottom: 0;
    }
  }
  .el-tabs__item {
    font-style: normal;
    font-size: 14px !important;
  }
  .el-tabs__content {
    min-height: 500px;
  }
}
</style>
