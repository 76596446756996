import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { SupervisorState } from "./types";
import { LivechatSessionType } from "@/store/modules/livechat/types";
import _ from "lodash";

const getters: GetterTree<SupervisorState, RootState> = {
  inQueueChatsArray(_state, _getters, _rootState, rootGetters) {
    const queueChatsArray = rootGetters.queueChatsArray as LivechatSessionType[];
    if (!queueChatsArray?.length || queueChatsArray.length === 0) {
      return [];
    }

    return queueChatsArray.filter((chat) => {
      if (!chat) {
        return false;
      }
      const assignedToAgent = chat.agents && chat.agents.length > 0;
      return !assignedToAgent && !chat.resolved;
    });
  },
  onGoingChatsArray(_state, _getters, _rootState, rootGetters) {
    const queueChatsArray = rootGetters.queueChatsArray as LivechatSessionType[];
    if (!queueChatsArray?.length || queueChatsArray.length === 0) {
      return [];
    }

    return queueChatsArray.filter((chat) => {
      if (!chat) {
        return false;
      }
      const assignedToAgent = chat.agents && chat.agents.length > 0;
      return assignedToAgent && !chat.resolved;
    });
  },
  abandonedChatsArray(_state, _getters, _rootState, rootGetters) {
    const abandonedChatsArray = rootGetters.abandonedChatsArray as LivechatSessionType[];
    if (!abandonedChatsArray?.length || abandonedChatsArray.length === 0) {
      return [];
    }

    return abandonedChatsArray;
  },
  onlineAgentsCount(_state, getters, _rootState, _rootGetters) {
    return getters.onlineAgentsWithChatCount?.length;
  },
  offlineAgentsCount(_state, getters, _rootState, _rootGetters) {
    return getters.offlineAgentsWithChatCount?.length;
  },
  totalChatsCount(_state, getters, _rootState, rootGetters) {
    return (
      getters.inQueueChatsArray.length +
      getters.onGoingChatsArray.length +
      rootGetters.abandonedChatsArray.length +
      rootGetters.resolvedChatsArray.length
    );
  },
  showOnlineAgents(state, _getters, _rootState) {
    return state.currentMode === "ONLINE_AGENTS";
  },
  showOfflineAgents(state, _getters, _rootState) {
    return state.currentMode === "OFFLINE_AGENTS";
  },
  selectedAgent(state, _getters, _rootState) {
    return state.selectedAgent;
  },
  selectedChatSession(state, _getters, _rootState) {
    return state.selectedChatSession;
  },
  agentsWithChatCount(state, _getters, _rootState) {
    return state.agentsWithChatCount;
  },
  onlineAgentsWithChatCount(_state, getters, _rootState) {
    return _.filter(getters.agentsWithChatCount, ({ agent }) => agent.status === "ONLINE");
  },
  offlineAgentsWithChatCount(_state, getters, _rootState) {
    return _.filter(getters.agentsWithChatCount, ({ agent }) => agent.status !== "ONLINE");
  },
};

export default getters;
