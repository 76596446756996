import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { AccessControlState } from "./types";
import _ from "lodash";

const getters: GetterTree<AccessControlState, RootState> = {
  users(state) {
    return state.auth0.users;
  },
  usersStatic(state) {
    return state.auth0.usersStatic;
  },
};

export default getters;
