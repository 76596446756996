<template>
  <div>
    <div style="padding: 8px; margin-bottom: 15px">
      <el-switch v-model="showObject" active-text="Show" inactive-text="Hide" />
      <JSONEditor
        v-if="showObject"
        ref="jsonEditor"
        v-model="value.data"
        style="margin-top: 10px; border: 2px solid #ccc"
      />
    </div>

    <EventNodeSelectorNext v-model="value" />
  </div>
</template>

<script>
import EventNodeSelectorNext from "../Next.vue";
import JSONEditor from "@/components/JSONEditor";

export default {
  name: "EventNodeTypeSet",
  components: {
    EventNodeSelectorNext,
    JSONEditor,
  },
  props: ["value"],
  data() {
    return {
      showObject: false,
    };
  },
};
</script>
