<template>
  <el-main>
    <el-row>
      <el-date-picker
        v-model="datePicked"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        :picker-options="datePickerOptions"
        :default-time="['00:00:00', '00:00:00']"
      />
    </el-row>
    <div v-loading="loadingCharts">
      <LineChart :labels="messagesLabels" :datasets="messagesDataset" :height="150" />
      <LineChart :labels="amountSpentLabels" :datasets="amountSpentDataset" :height="150" />
    </div>
  </el-main>
</template>
<script>
import moment from "moment";
import LineChart from "@/components/LineChart";

export default {
  components: {
    LineChart,
  },
  name: "AnalyticsTab",
  props: {
    wa_id: String,
    wa_endpoint: String,
  },
  data() {
    return {
      loadingCharts: false,
      amountSpentLabels: [],
      amountSpentDataset: [],
      messagesLabels: [],
      messagesDataset: [],
      datePicked: [moment().utc().subtract(1, "month"), moment().utc()],
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const start = new Date();
              start.setHours(-24);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const start = new Date();
              start.setDate(start.getDate() - 7);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "All-Time",
            onClick(picker) {
              const start = new Date();
              start.setFullYear(1990, 1, 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    updateAnalyticsData(start, end) {
      this.loadingCharts = true;
      this.$store
        .dispatch("GET_ACCOUNT_ANALYTICS", {
          data: {
            start,
            end,
            phone_number: this.wa_id,
            wa_endpoint: this.wa_endpoint,
          },
        })
        .then((data) => {
          this.loadingCharts = false;
          this.messagesLabels = data.map((obj) => obj.start * 1000);
          this.amountSpentLabels = data.map((obj) => obj.start * 1000);
          const messagesSent = {
            label: "Messages Sent",
            backgroundColor: "#06f5fb",
            data: _.map(data, (obj) => obj.sent),
          };
          const messagesDelivered = {
            label: "Messages Delivered",
            backgroundColor: "#1f6ff6",
            data: _.map(data, (obj) => obj.delivered),
          };

          const amountSpent = {
            label: "Amount Spent",
            backgroundColor: "#ffa64d",
            data: _.map(data, (obj) => (obj.sent * 0.05).toFixed(2)),
          };
          this.messagesDataset = [messagesSent, messagesDelivered];
          this.amountSpentDataset = [amountSpent];
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  mounted() {
    const timezone = this.$store.state.modules.whatsapp.timezone
      ? this.$store.state.modules.whatsapp.timezone
      : 8;
    const start = this.datePicked[0].startOf("day").subtract(timezone, "hours").unix();
    const end = this.datePicked[1].add(1, "day").startOf("day").subtract(timezone, "hours").unix();
    this.updateAnalyticsData(start, end, this.filterParams);
  },
  watch: {
    datePicked(newDateArr) {
      const timezone = this.$store.state.modules.whatsapp.timezone
        ? this.$store.state.modules.whatsapp.timezone
        : 8;
      const cloneStart = _.cloneDeep(newDateArr[0]);
      const cloneEnd = _.cloneDeep(newDateArr[1]);
      cloneStart.setMinutes(
        -cloneStart.getTimezoneOffset() - cloneStart.getMinutes() - timezone * 60
      );

      cloneEnd.setDate(cloneEnd.getDate() + 1);
      cloneEnd.setHours(0, 0, 0, 0);
      cloneEnd.setMinutes(-cloneEnd.getTimezoneOffset() - cloneEnd.getMinutes() - timezone * 60);
      const start = Math.floor(cloneStart.getTime() / 1000);
      const end = Math.floor(cloneEnd.getTime() / 1000);
      this.updateAnalyticsData(start, end, this.filterParams);
    },
  },
};
</script>
