<template>
  <el-main style="height: 100%; padding: 0">
    <el-row :gutter="20" style="width: 100%">
      <el-col :md="24" :lg="12">
        <el-card style="margin-top: 10px">
          <span style="font-size: 24px; font-weight: lighter">
            <b>Application Settings</b>
          </span>
          <br />
          <br />
          <el-form
            class="application-form"
            ref="applicationSettingsForm"
            :model="applicationSettingsForm"
            :label-position="'left'"
            label-width="160px"
            :rules="applicationSettingsFormRules"
          >
            <el-form-item label="Webhook URL" prop="webhookURL">
              <el-input
                class="application-form-item"
                v-model="applicationSettingsForm.webhookURL"
              ></el-input>
              <el-tooltip
                effect="dark"
                content="The configured URL will receive all incoming messages which are sent to the registered number. Example: https://(subdomain).(domain).com/webhook/whatsapp/6588372482"
                placement="left"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Callback Backoff Delay (ms)" prop="callbackDelay">
              <el-input
                class="application-form-item"
                v-model="applicationSettingsForm.callbackDelay"
              ></el-input>
              <el-tooltip
                effect="dark"
                content="Used to configure the amount of time the backoff delays before retrying a failed callback. Callback time will increase linearly as it fails to deliver up to the Max Callback Backoff Delay."
                placement="left"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Max Callback Backoff Delay (ms)" prop="maxCallbackDelay">
              <el-input
                class="application-form-item"
                v-model="applicationSettingsForm.maxCallbackDelay"
              ></el-input>
              <el-tooltip
                effect="dark"
                content="Maximum delay for a failed callback in milliseconds. Used with Callback Backoff Delay."
                placement="left"
              >
                <i class="el-icon-question has-text-grey" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Callback Persist" prop="callbackPersist">
              <el-checkbox v-model="applicationSettingsForm.callbackPersist">
                Callback Persist
              </el-checkbox>
              <el-tooltip
                effect="dark"
                content="Stores callbacks on disk until they are successfully acknowledged by the Webhook or not. WhatsApp server restart required."
                placement="left"
              >
                <i class="el-icon-question has-text-grey" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Max Concurrent Requests">
              <el-select v-model="applicationSettingsForm.maxConcurrentRequests">
                <el-option
                  v-for="maxRequestAmount in maxConcurrentRequestsOptions"
                  :key="maxRequestAmount"
                  :label="maxRequestAmount"
                  :value="maxRequestAmount"
                ></el-option>
              </el-select>
              <el-tooltip
                effect="dark"
                content="Configures the maximum number of inflight callback requests that are sent out. Can be set to 6 (default), 12, 18, or 24. WhatsApp server restart required."
                placement="left"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Unhealthy Interval (sec)" prop="unhealthyInterval">
              <el-input
                class="application-form-item"
                v-model="applicationSettingsForm.unhealthyInterval"
              ></el-input>
              <el-tooltip
                effect="dark"
                content="Maximum amount of seconds a Master node waits for a Coreapp node to respond to a heartbeat before considering it unhealthy and starting the failover process. (Multiconnect Only)"
                placement="left"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Heartbeat Interval (sec)" prop="heartbeatInterval">
              <el-input
                class="application-form-item"
                v-model="applicationSettingsForm.heartbeatInterval"
              ></el-input>
              <el-tooltip
                effect="dark"
                content="Interval of the Master node monitoring of Coreapp nodes in seconds (Multiconnect Only)"
                placement="left"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Webhooks Messages">
              <el-checkbox v-model="applicationSettingsForm.webhookMessage.sent">Sent</el-checkbox>
              <el-checkbox v-model="applicationSettingsForm.webhookMessage.delivered">
                Delivered
              </el-checkbox>
              <el-checkbox v-model="applicationSettingsForm.webhookMessage.read">Read</el-checkbox>
              <el-tooltip effect="dark" content="Set active webhooks" placement="right">
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="Auto Download Media">
              <el-checkbox v-model="applicationSettingsForm.autoDownload.audio">Audio</el-checkbox>
              <el-checkbox v-model="applicationSettingsForm.autoDownload.document">
                Document
              </el-checkbox>
              <el-checkbox v-model="applicationSettingsForm.autoDownload.voice">Voice</el-checkbox>
              <el-checkbox
                style="margin-left: 0"
                v-model="applicationSettingsForm.autoDownload.video"
                >Video</el-checkbox
              >
              <el-checkbox v-model="applicationSettingsForm.autoDownload.image">Image</el-checkbox>
              <el-tooltip effect="dark" content="List of media to auto-download" placement="right">
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
              </el-tooltip>
            </el-form-item>
            <el-button :loading="saving" type="primary" @click="save">Save</el-button>
          </el-form>
        </el-card>
      </el-col>
      <ProfileSettingsTab
        :profile_settings="profile_settings"
        :application_settings="application_settings"
        :wa_endpoint="wa_endpoint"
      ></ProfileSettingsTab>
    </el-row>
  </el-main>
</template>

<script>
import _ from "lodash";
import ProfileSettingsTab from "./ProfileSettingsTab.vue";

export default {
  name: "ApplicationSettingsTab",
  components: {
    ProfileSettingsTab,
  },
  props: {
    groups: Array,
    application_settings: Object,
    profile_settings: Object,
    wa_endpoint: String,
  },
  data() {
    return {
      applicationSettingsFormRules: {},
      saving: false,
      maxConcurrentRequestsOptions: ["6", "12", "18", "24"],
      applicationSettingsForm: {
        webhookURL: "",
        callbackDelay: "",
        maxCallbackDelay: "",
        callbackPersist: "",
        maxConcurrentRequests: "",
        unhealthyInterval: "",
        heartbeatInterval: "",
        autoDownload: {
          audio: false,
          document: false,
          voice: false,
          video: false,
          image: false,
        },
        webhookMessage: {
          delivered: true,
          read: true,
          sent: true,
        },
      },
    };
  },
  methods: {
    formatLabel(id, subject) {
      return `${subject} (${id})`;
    },
    updateApplicationSettingsForm() {
      const settings = this.application_settings;
      if (settings && Object.keys(settings).length > 0) {
        this.applicationSettingsForm.webhookURL = settings.webhooks.url;
        this.applicationSettingsForm.webhookMessage.delivered = settings.webhooks.message.delivered;
        this.applicationSettingsForm.webhookMessage.read = settings.webhooks.message.read;
        this.applicationSettingsForm.webhookMessage.sent = settings.webhooks.message.sent;
        this.applicationSettingsForm.callbackDelay = settings.callback_backoff_delay_ms;
        this.applicationSettingsForm.maxCallbackDelay = settings.max_callback_backoff_delay_ms;
        this.applicationSettingsForm.callbackPersist = settings.callback_persist;
        this.applicationSettingsForm.maxConcurrentRequests =
          settings.webhooks.max_concurrent_requests;
        this.applicationSettingsForm.unhealthyInterval = settings.unhealthy_interval;
        this.applicationSettingsForm.heartbeatInterval = settings.heartbeat_interval;
        this.applicationSettingsForm.autoDownload = settings.media.auto_download.reduce(
          (obj, item) => {
            obj[item] = true;
            return obj;
          },
          {}
        );
      }
    },
    save() {
      this.$refs.applicationSettingsForm.validate((valid) => {
        if (valid) {
          this.$data.saving = true;
          const {
            webhookURL,
            webhookMessage,
            callbackDelay,
            maxCallbackDelay,
            callbackPersist,
            maxConcurrentRequests,
            unhealthyInterval,
            heartbeatInterval,
            autoDownload,
          } = this.applicationSettingsForm;
          const mediaDownloadSettings = _.map(autoDownload, (val, key) => {
            if (val) return key;
          });
          this.$store
            .dispatch("UPDATE_WHATSAPP_APPLICATION_SETTINGS", {
              data: {
                wa_endpoint: this.wa_endpoint,
                callback_persist: callbackPersist,
                max_callback_backoff_delay_ms: maxCallbackDelay,
                callback_backoff_delay_ms: callbackDelay,
                webhooks: {
                  url: webhookURL,
                  message: {
                    delivered: webhookMessage.delivered,
                    read: webhookMessage.read,
                    sent: webhookMessage.sent,
                  },
                  max_concurrent_requests: maxConcurrentRequests,
                },
                max_concurrent_requests: maxConcurrentRequests,
                unhealthy_interval: unhealthyInterval,
                heartbeat_interval: heartbeatInterval,
                media: { auto_download: mediaDownloadSettings },
              },
            })
            .then((data) => {
              this.$data.saving = false;
              this.$message({
                type: "success",
                message: "Application settings updated!",
              });
            })
            .catch((err) => {
              this.$data.saving = false;
              this.$message({
                type: "error",
                message: "Error occured while updating application settings!",
              });
            });
        } else {
          this.$message({
            type: "error",
            message: "Submit Error.",
          });
          return false;
        }
      });
    },
  },
  created() {
    this.updateApplicationSettingsForm();
  },
  watch: {
    application_settings() {
      this.updateApplicationSettingsForm();
    },
  },
};
</script>

<style scoped>
.application-form {
  width: 600px;
}

.application-form-item {
  width: 400px;
}
</style>
