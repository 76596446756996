<template>
  <div class="changes-container">
    <div v-if="havingChanges" v-html="getChanges()" class="changes-wrapper"></div>
    <div
      v-else
      class="changes-wrapper"
      style="place-content: center; place-items: center; display: grid"
    >
      There is no change has been made to this form yet.
    </div>
    <div class="actions">
      <el-button @click="onSave" type="success" :disabled="!havingChanges">
        <el-icon name="check"></el-icon>
        Save
      </el-button>
      <el-button @click="onRevert" type="warning" :disabled="!havingChanges">
        <el-icon name="close"></el-icon>
        Revert
      </el-button>
      <el-button @click="onDelete" type="danger" v-if="!form.settings?.nonDelete">
        <el-icon name="remove"></el-icon>
        Delete
      </el-button>
    </div>
  </div>
</template>

<script>
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import _ from "lodash";

export default {
  name: "FormDetailActions",
  props: {
    form: Object,
    originalForm: Object,
  },
  mounted() {
    this.getChanges();
  },
  methods: {
    getChanges() {
      jsondiffpatch.formatters.html.hideUnchanged();

      const delta = jsondiffpatch.diff(this.originalForm, this.form);
      const changes = jsondiffpatch.formatters.html.format(delta, this.originalForm);
      return changes;
    },
    onSave() {
      this.$emit("form-action", {
        action: "save",
        callback: () => {
          this.originalForm.name = this.form.name;
          this.originalForm.description = this.form.description;
          this.originalForm.settings = { ...this.form.settings };
        },
      });
    },
    onDelete() {
      this.$confirm("Do you want to delete to form and its related submit results?")
        .then(() => {
          this.$emit("form-action", { action: "delete" });
        })
        .catch(() => {});
    },
    onRevert() {
      this.$confirm("Do you want to revert to original form?")
        .then(() => {
          this.form.name = this.originalForm.name;
          this.form.description = this.originalForm.description;
          this.form.settings = { ...this.originalForm.settings };
          this.$emit("form-action", { action: "reverted" });
        })
        .catch(() => {});
    },
  },
  computed: {
    havingChanges() {
      return !_.isEqual(this.originalForm, this.form);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~jsondiffpatch/dist/formatters-styles/html.css";

.changes-container {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: auto;

  .changes-wrapper {
    margin: 20px;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    background: #fdfdfd;
    border-radius: 4px;
    overflow: auto;
  }

  .actions {
    float: right;
    margin: 0 20px 20px;
    text-align: right;
  }
}
</style>
