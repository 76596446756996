<template>
  <el-container style="border: 1px solid #eee" class="outer">
    <el-main>
      <el-tabs v-model="activeTab">
        <!-- Manage Roles -->
        <el-tab-pane name="roles" label="Roles">
          <TabRoles />
        </el-tab-pane>

        <!-- comment for future feature -->
        <!-- Manager out reach tags -->
        <!-- <el-tab-pane name="tag" label="Tags">
          <TabTags />
        </el-tab-pane> -->

        <!-- Manager out reach stages -->
        <!-- <el-tab-pane name="stage" label="Stages">
          <TabStages />
        </el-tab-pane> -->

        <!-- Manage Departments -->
        <el-tab-pane name="department" label="Departments">
          <TabDepartments />
        </el-tab-pane>

        <!-- Manage Users -->
        <!-- Disable user tab in case of keycloak -->
        <el-tab-pane v-if="notKeycloakAuth" name="users" label="Users">
          <!-- Dialog for Add new Auth0 user -->
          <TabUsers :is-fetching-users="isFetchingUsers" />
        </el-tab-pane>
        <!-- <el-tab-pane name="activeusers" label="Active Users">
          <TabActiveUsers v-if="activeTab === 'activeusers'" />
        </el-tab-pane> -->
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import { mapGetters } from "vuex";
import TabRoles from "./TabRoles/Index";
import TabUsers from "./TabUsers/Index";
import TabDepartments from "./TabDepartments/Index";
// import TabTags from "./TabTags/Index";
// import TabStages from "./TabStages/Index";
import { getAuthType } from "@/auth";

export default {
  components: {
    TabRoles,
    TabUsers,
    TabDepartments,
    // TabTags,
    // TabStages,
  },
  data() {
    return {
      activeTab: "roles",
      isFetchingDepartments: false,
      isFetchingUsers: false,
    };
  },
  methods: {
    fetchUsers() {
      this.isFetchingUsers = true;
      this.$store
        .dispatch("FETCH_AUTH_USERS")
        .catch((err) => {
          this.$notify.error({
            title: "Fetch Error",
            message: "Encountered error fetching auth users",
            position: "bottom-right",
          });
        })
        .finally(() => (this.isFetchingUsers = false));
    },
    fetchDepartments() {
      this.$store
        .dispatch("FETCH_DEPARTMENTS")
        .catch((err) => {
          this.$notify.error({
            title: "Fetch Error",
            message: "Encountered error fetching departments",
            position: "bottom-right",
          });
        })
        .finally(() => (this.isFetchingDepartments = false));
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchDepartments();
    this.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
  },
  computed: {
    notKeycloakAuth() {
      return getAuthType() !== "KEYCLOAK";
    },
  },
};
</script>
