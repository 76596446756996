import { graph } from "@/store/api";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { SupervisorState } from "./types";
import gql from "graphql-tag";
import _ from "lodash";

const actions: ActionTree<SupervisorState, RootState> = {
  SHOW_AGENT_LIST({ state, commit }, { status }) {
    if (state.currentExpanded) {
      commit("SET_CURRENT_EXPANDED", null);
    }
    console.log(`fetching ${status} agents list`);
    if (!status) {
      commit("SET_CURRENT_MODE", null);
    }
  },
  SHOW_ONLINE_AGENTS({ commit, dispatch }) {
    commit("SET_CURRENT_MODE", "ONLINE_AGENTS");
    dispatch("SHOW_AGENT_LIST", { status: "online" });
  },
  SHOW_OFFLINE_AGENTS({ commit, dispatch }) {
    commit("SET_CURRENT_MODE", "OFFLINE_AGENTS");
    dispatch("SHOW_AGENT_LIST", { status: "offline" });
  },
  FETCH_AGENTS_WITH_CHAT_COUNT: async ({ commit }, { email }) => {
    // const response = await graph.query({
    //   query: gql`
    //     query {
    //       livechatAPI {
    //         agentsWithChatCount
    //       }
    //     }
    //   `,
    //   fetchPolicy: "network-only",
    // });
    const response = await graph.query({
      query: gql`
        query ($email: String) {
          livechatAPI {
            agentsWithChatCountByDepartment(email: $email)
          }
        }
      `,
      variables: {
        email,
      },
      fetchPolicy: "network-only",
    });
    const agentsWithChatCount = _.get(
      response,
      "data.livechatAPI.agentsWithChatCountByDepartment",
      {}
    );
    commit("SET_AGENTS_WITH_CHAT_COUNT", agentsWithChatCount);
  },
};

export default actions;
