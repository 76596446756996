<template>
  <el-card v-if="value[name]" style="margin-bottom: 20px">
    <div slot="header">
      <h3>
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </h3>
    </div>
    <div style="display: flex; flex-direction: column">
      <component
        v-model="value[name]"
        v-for="(childSetting, childKey) in setting.children"
        :key="childKey"
        :name="childKey"
        :setting="childSetting"
        :is="toComponent(childSetting)"
        :modules="modules"
      ></component>
    </div>
  </el-card>
</template>

<script>
import _ from "lodash";
import SmartFieldString from "./String";
import SmartFieldList from "./List";
import SmartFieldNumber from "./Number";
import SmartFieldAutocomplete from "./Autocomplete";
import SmartFieldTimerange from "./Timerange";
import SmartFieldDaterange from "./Daterange";
import SmartFieldDate from "./Date";
import SmartFieldTag from "./Tag";
import SmartFieldBoolean from "./Boolean";
import SmartFieldColor from "./Color";
import SmartFieldSelect from "./Select";
import SmartFieldMultiselect from "./Multiselect";
import SmartFieldTextarea from "./Textarea";
import SmartFieldJson from "./Json";
import SmartFieldButton from "./Button";
import SmartFieldTransfer from "./Transfer";
import SmartFieldCascader from "./Cascader";
import SmartFieldCascaderwithheaders from "./CascaderWithHeaders";
// import SmartFieldHandoverroutingskillslibrary from "./Custom/HandoverRoutingSkillsLibrary";
import SmartFieldOfficehourdepartmentdatetimes from "./Custom/OfficeHourDepartmentDatetimes";
import SmartFieldOfficehourdatetimes from "./Custom/OfficeHourDatetimes";
import SmartFieldStickymenulanguage from "./Custom/StickyMenuLanguage";
import SmartFieldSingpasscertificate from "./Custom/SingpassCertificate";
import SmartFieldTrendingtopiclanguage from "./Custom/TrendingTopicLanguage";

import Tooltip from "../Tooltip.vue";
export default {
  props: ["value", "setting", "name", "modules"],
  components: {
    Tooltip,
    SmartFieldString,
    SmartFieldList,
    SmartFieldNumber,
    SmartFieldAutocomplete,
    SmartFieldTimerange,
    SmartFieldDaterange,
    SmartFieldDate,
    SmartFieldTag,
    SmartFieldBoolean,
    SmartFieldColor,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
    SmartFieldJson,
    SmartFieldButton,
    SmartFieldTransfer,
    SmartFieldCascader,
    SmartFieldCascaderwithheaders,
    // SmartFieldHandoverroutingskillslibrary,
    SmartFieldOfficehourdepartmentdatetimes,
    SmartFieldOfficehourdatetimes,
    SmartFieldObject: () => import("@/components/ModuleEditor/SmartFields/Object"),
    SmartFieldStickymenulanguage,
    SmartFieldSingpasscertificate,
    SmartFieldTrendingtopiclanguage,
  },
  mounted() {
    const isNotObject = !_.isObject(this.value[this.name]);
    const isEmpty = _.isEmpty(this.value[this.name]);
    if ((isNotObject || isEmpty) && typeof this.value === "object") {
      this.$set(this.value, this.name, this.setting.default);
    }
  },
  methods: {
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
  },
};
</script>
