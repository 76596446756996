var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$store.state.loaded < 2 && _vm.$route.name !== 'error' && !_vm.$store.state.showAdvanced),expression:"$store.state.loaded < 2 && $route.name !== 'error' && !$store.state.showAdvanced"}],staticStyle:{"height":"100%"},attrs:{"element-loading-text":_vm.randomLoadingMessage}},[(_vm.$route.name !== 'error' && _vm.$route.name !== 'logout')?_c('el-aside',{staticClass:"aside-bar",style:({
      height: _vm.collapse ? '50px' : '100%',
      // overflowY: 'hidden',
    }),attrs:{"width":"auto"}},[(_vm.$device.Mobile)?_c('el-menu',{staticStyle:{"background":"transparent"}},[_c('el-menu-item',{staticClass:"sidenav-item",on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_c('i',{class:{
            'el-icon-arrow-down': _vm.collapse,
            'el-icon-arrow-up': !_vm.collapse,
          }})])],1):_vm._e(),_c('side-nav',{staticStyle:{"border":"0"}}),_c('div',{staticClass:"version"},[_c('span',[_vm._v(_vm._s(_vm.releaseId.simple))])])],1):_vm._e(),_c('el-main',{staticClass:"outer",style:({
      'margin-left': _vm.$device.Mobile ? 0 : '65px',
    })},[(_vm.$auth.isAuthenticated)?[_c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"show-close":false,"visible":_vm.inactiveDialogVisible,"width":"60vw","center":""},on:{"update:visible":function($event){_vm.inactiveDialogVisible=$event}}},[_c('span',{staticClass:"session-timeout-header",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"el-icon-warning has-text-warning",staticStyle:{"position":"relative","right":"5px"}}),_c('label',[_vm._v("Session Timeout")])]),_c('span',{staticClass:"session-timeout-body"},[_vm._v(" You're about to be logged out for security purposes. Please click on \"Continue\" to remain logged in, or \"Logout\" to sign out of the dashboard. ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"session-timeout-btn",attrs:{"type":"primary","disabled":_vm.isContinueButtonDisabled},on:{"click":_vm.closeAllDialogs}},[_vm._v(" Continue ("+_vm._s(_vm.secondsOfInactivityRemaining)+") ")]),_c('el-button',{staticClass:"session-timeout-btn",attrs:{"disabled":_vm.isLogoutButtonDisabled},on:{"click":_vm.sessionLogout}},[_vm._v(" Logout ")])],1)]),_c('transition',{attrs:{"name":"el-fade-in"}},[(_vm.$store.state.loaded >= 2 || _vm.$route.name === 'error')?_c('router-view'):_vm._e()],1)]:_vm._e(),(_vm.$store.state.showDeveloperConsole)?_c('DeveloperConsole'):_vm._e(),_c('IntentDrawer'),_c('WebWidget')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }