<template>
  <el-row :gutter="25" class="video">
    <el-col :span="12" class="form">
      <el-row class="header">
        <el-radio-group
          v-model="video.options.type"
          class="group"
          @change="switchVideoForm"
          :disabled="loading"
        >
          <el-radio-button label="Generate Video" class="item"></el-radio-button>
          <el-radio-button label="Upload" class="item"></el-radio-button>
          <el-radio-button label="My Videos" class="item"></el-radio-button>
        </el-radio-group>
      </el-row>
      <el-form class="body" :disabled="loading">
        <template v-if="video.options.type === 'Generate Video'">
          <el-form-item>
            <span slot="label">Add video script</span>
            <el-input
              type="textarea"
              v-model="video.subtext"
              @input="inputVideoScripts"
              @change="generateVideoScriptsToArray"
              maxlength="600"
              show-word-limit
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Video subtitles</span>
            <el-switch
              v-model="video.options.isShowSubtitles"
              active-color="#13ce66"
              style="margin-left: 20px"
              @change="generateVideoScriptsToArray"
            ></el-switch>
          </el-form-item>
          <el-form-item>
            <span slot="label">Video title</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="Title is video name which won't be shown to the visitors."
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
            <el-input v-model="video.options.template.title"></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Choose a template</span>
            <el-select
              v-model="video.options.template.templateId"
              placeholder="Select template..."
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in fetchTemplates"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                style="padding-left: 10px"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span slot="label">Visibility</span>
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content">Describes the private settings of the video</div>
              <i class="el-icon-info" style="color: grey; margin-left: 5px; margin-right: 20px"></i>
            </el-tooltip>
            <el-radio
              v-model="video.options.template.visibility"
              label="private"
              :disabled="loading"
            >
              Private
            </el-radio>
            <el-radio
              v-model="video.options.template.visibility"
              label="public"
              :disabled="loading"
            >
              Public
            </el-radio>
          </el-form-item>
        </template>
        <template v-else-if="video.options.type === 'Upload'">
          <el-form-item>
            <span slot="label">Upload video from local file</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="Upload webm, m4v or mp4 files, size less than 50MB."
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
          </el-form-item>
          <el-row class="upload">
            <input
              id="localFileVideo"
              accept="video/webm, video/mp4"
              ref="localFileVideo"
              type="file"
              @change="uploadVideoLocalToAzure"
              :disabled="loading"
            />
            <template v-if="!video.url || !video.options.videoName">
              <label for="localFileVideo" class="btn empty">
                <span class="icon">
                  <i class="el-icon-upload"></i>
                </span>
                <span>Upload Video</span>
              </label>
              <span style="margin-left: 5px" v-if="loading"
                ><i style="color: #e4392b; font-size: 24px" class="el-icon-loading"></i
              ></span>
            </template>
            <template v-else>
              <label class="btn success">
                <span class="icon">
                  <i class="el-icon-check"></i>
                </span>
                <span>Video Uploaded</span>
              </label>
            </template>
          </el-row>
          <el-row v-if="video.options.videoName">
            <el-col :span="12">
              <el-alert
                @close="deleteVideoLocal"
                :disable-transactions="false"
                type="info"
                style="width: 100%; padding: 12px"
                closable
              >
                <i
                  class="el-icon-document-checked"
                  style="color: #e4392b; font-size: 25px; margin-right: 5px"
                ></i
                >{{ video.options.videoName }}
              </el-alert>
            </el-col>
          </el-row>
          <el-form-item>
            <span slot="label">Video subtitles</span>
            <el-switch
              v-model="video.options.isShowSubtitles"
              active-color="#13ce66"
              style="margin-left: 20px"
              @change="generateVideoScriptsToArray"
            ></el-switch>
            <el-input
              type="textarea"
              v-model="video.subtext"
              @input="inputVideoScripts"
              @change="generateVideoScriptsToArray"
              maxlength="600"
              show-word-limit
              :rows="3"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else-if="video.options.type === 'My Videos'">
          <el-form-item>
            <span slot="label">Your Videos</span>
            <i
              class="el-icon-loading"
              style="margin-left: 10px; color: red; font-size: 20px"
              v-if="!myVideos.length"
            ></i>
          </el-form-item>
          <div style="display: flex; align-items: center; margin: 0" v-if="myVideos.length">
            <ul class="carousel">
              <li
                v-for="(video, index) in myVideos"
                :key="video.id"
                v-loading="index === videoIsDeleting"
              >
                <div class="item">
                  <video
                    :src="video.download"
                    @click="handleSelectedMyVideo(index)"
                    :class="index === selectedVideo ? 'selected' : ''"
                  />
                  <i class="el-icon-close icon-delete" @click="handleDeleteVideoSynth(index)"></i>
                  <div style="width: 90%; overflow-x: hidden; text-overflow: ellipsis">
                    <span>{{ video.title ? video.title : "New Video" }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div style="color: red">
              <div @click="handlePreviousListVideo" v-if="currentPage > 0">
                <i class="el-icon-top"></i>
              </div>
              <div
                @click="handleNextListVideo"
                v-if="currentPage < Math.floor(fetchVideos.length / 6)"
              >
                <i class="el-icon-bottom"></i>
              </div>
            </div>
          </div>
          <el-form-item>
            <span slot="label">Video subtitles</span>
            <el-switch
              v-model="video.options.isShowSubtitles"
              active-color="#13ce66"
              style="margin-left: 20px"
              @change="generateVideoScriptsToArray"
            ></el-switch>
            <el-input
              type="textarea"
              v-model="video.subtext"
              @input="inputVideoScripts"
              @change="generateVideoScriptsToArray"
              maxlength="600"
              show-word-limit
              :rows="3"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <span slot="label">Text Message</span>
          <el-tooltip class="item" effect="dark" placement="right">
            <div slot="content">
              Visitors will see this message as text format<br />if the video is minimized or fails
              to load
            </div>
            <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
          </el-tooltip>
          <el-input
            type="textarea"
            v-model="video.text"
            maxlength="600"
            show-word-limit
            required
            :rows="3"
          />
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="end" v-if="video.options.type === 'Generate Video'">
        <el-button
          type="primary"
          class="btn"
          @click="generateVideoSynthesia"
          :loading="loading"
          :disabled="!isChangeValidFormSynth"
          >Process Video</el-button
        >
      </el-row>
      <el-row>
        <el-col :span="9">
          <Buttons
            v-if="showButtons"
            v-model="video.buttons"
            :editable="editable"
            :limit="3"
            @addButton="$emit('addButton')"
            @save-side-effect="$emit('save-side-effect')"
          />
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="12" class="preview">
      <el-row class="container">
        <div
          class="header"
          :style="{
            'background-color': webChatColor.headerBackgroundColor,
            color: webChatColor.headerFontColor,
            'border-bottom': `5px solid ${webChatColor.headerBorderColor}`,
          }"
        >
          Video Preview
        </div>
        <div
          class="body"
          v-loading="loading"
          element-loading-text="Video Processing..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 1)"
        >
          <div v-if="video.url">
            <video
              class="video"
              ref="videoPreview"
              @click="toggleVideo"
              @error="handleErrorVideo"
              @loadedmetadata="handleSuccessVideo"
              @ended="toggleVideo"
              @timeupdate="updateCurrentScript"
              :src="video.url"
            />
            <div class="scripts" v-if="video.options.isShowSubtitles && currentScript">
              <p>
                {{ currentScript }}
              </p>
            </div>
            <div
              class="btn-pause"
              v-if="!isPlay && !loading"
              :style="{ 'background-image': 'url(img/icons/play-button-980x980.png)' }"
              @click="toggleVideo"
            ></div>
            <div class="buttons-overlay" :style="!disableTextInput ? 'bottom: 55px' : 'bottom: 0px'">
              <div v-for="(button, index) in video.buttons" :key="index" class="btn">
                <div class="choice">
                  {{ String.fromCharCode(97 + index).toUpperCase() }}
                </div>
                <div class="title">
                  {{ button.text }}
                </div>
              </div>
            </div>
            <div class="chat-input-wrapper" v-show="!disableTextInput">
              <input
                type="text"
                v-model="chatInput"
                placeholder="Type a message..."
                autocomplete="off"
                tabindex="-1"
              />
              <i class="el-icon-s-promotion btn-send"></i>
            </div>
          </div>
          <div class="placeholder" v-else>Preview Video</div>
        </div>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import gql from "graphql-tag";
import _ from "lodash";
const FIFTY_MEGABYTES = 50 * 1024 * 1024;
export default {
  name: "Video",
  components: {
    Buttons: () => import("@/components/Buttons/Index"),
  },
  data() {
    return {
      timeLimitVideo:
        (this.$store.state.modules.synthesia.timeLimitLoadingVideoInMinutes || 3) * 60,
      timeReloadVideo: 5,
      timeChangeCurrentScript: this.$store.state.modules.synthesia.timeChangeScriptInSeconds || 6,

      arrayVideoScripts: [],
      currentSentences: 0,

      loading: false,
      isPlay: false,

      fetchVideos: [],
      fetchTemplates: [],

      videoIsDeleting: -1,
      selectedVideo: -1,
      currentPage: 0,
      PAGING: 6,

      oldVideoData: {
        text: this.video.text,
        subtext: this.video.subtext,
        templateId: this.video.options.template.templateId,
      },

      chatInput: ""
    };
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showButtons: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disableTextInput: {
      type: Boolean,
      default() {
        return true;
      },
    },
    video: {
      default() {
        return {
          text: "",
          buttons: [],
          subtext: "",
          url: "",
          options: {
            mode: "video",
            type: "Generate Video",
            isShowSubtitles: false,
            videoName: "",
            template: {
              test: true,
              callbackId: "",
              title: "",
              templateId: "",
              templateData: {
                video_script: "",
              },
              description: "",
              visibility: "public",
            },
          },
        };
      },
    },
  },
  computed: {
    webChatColor() {
      return this.$store.state.modules.webchat;
    },
    currentScript() {
      if (this.arrayVideoScripts.length === 0) return false;
      return this.arrayVideoScripts[this.currentSentences];
    },
    myVideos() {
      return _.take(_.drop(this.fetchVideos, this.currentPage * this.PAGING), this.PAGING);
    },
    isChangeValidFormSynth() {
      return (
        (this.video.subtext !== this.oldVideoData.subtext ||
          this.video.text !== this.oldVideoData.text ||
          this.video.options.template.templateId !== this.oldVideoData.templateId) &&
        this.isNotEmptyFieldsSynth
      );
    },
    isNotEmptyFieldsSynth() {
      return (
        this.video.subtext !== "" &&
        this.video.text !== "" &&
        this.video.options.template.templateId !== ""
      );
    },
  },
  methods: {
    switchVideoForm(value) {
      this.video.options.type = value;
    },

    async uploadVideoLocalToAzure(event) {
      this.loading = true;
      this.$message({
        showClose: true,
        message: "Video local is processing...",
        type: "info",
      });
      const file = event.target.files[0];
      if (file.size >= FIFTY_MEGABYTES) {
        this.$message({
          showClose: true,
          message: "Oops, files's size less than 50megabytes. Please upload another video!",
          type: "error",
        });
        this.$refs.videoLocal.value = "";
        this.loading = false;
        return;
      }
      const form = new FormData();
      form.append("brain", this.$store.state.brain);
      form.append("name", "videosLocal");
      form.append("videos", file);
      try {
        const result = await this.$rest("post", "miniapp_videos_upload", form);
        this.video.url = result[0].url;
        this.loading = false;
        this.formatVideoData();
      } catch (error) {
        this.loading = false;
        this.$message({
          showClose: true,
          message: "Oops, Upload video local failed",
          type: "error",
        });
      }
    },

    async deleteVideoLocal() {
      this.loading = true;
      try {
        await this.deleteFileAzure();
        this.video.options.videoName = "";
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({
          type: "error",
          message: "Error occurred while trying to delete video!",
        });
      }
    },

    async generateVideoSynthesia() {
      this.loading = true;
      this.video.options.template.templateData.video_script = this.video.subtext;
      try {
        this.$message({
          showClose: true,
          message: "Generate Video Synthesia is processing...",
          type: "info",
        });
        await this.$rest("post", "create_video_from_template", this.video.options.template);
        this.formatVideoData();
      } catch (error) {
        this.loading = false;
        this.$message({
          showClose: true,
          message: "Oops, Generate Synthesia video failed",
          type: "error",
        });
      }
    },
    async fetchListVideo() {
      try {
        const videos = await this.$rest("get", `get_list_videos_synthesia`);
        this.fetchVideos = videos;
      } catch (error) {
        this.fetchVideos = [];
        console.log(error);
      }
    },
    async fetchListTemplate() {
      try {
        const templates = await this.$rest("get", `retrieve_list_template_synthesia`);
        this.fetchTemplates = templates;
      } catch (error) {
        this.fetchTemplates = [];
        console.log(error);
      }
    },

    handlePreviousListVideo() {
      this.selectedVideo = -1;
      if (this.currentPage < 1) return;
      this.currentPage--;
    },
    handleNextListVideo() {
      this.selectedVideo = -1;
      if (this.currentPage > Math.ceil(this.fetchVideos.length)) return;
      this.currentPage++;
    },
    handleSelectedMyVideo(index) {
      this.isChange = true;
      this.selectedVideo = index;
      this.video.url = this.myVideos[index].download;
      this.formatVideoData();
    },

    async handleDeleteVideoSynth(index) {
      this.videoIsDeleting = index;
      const video = this.myVideos[index];
      this.$confirm("Are you sure you want to delete this video?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          const isCurrentVideo = this.video.url.includes(video.id);
          try {
            this.myVideos.splice(index, 1);
            await this.$rest("get", `delete_video_by_id?id=${video.id}`);
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            await this.fetchListVideo();
            if (isCurrentVideo) {
              this.selectedVideo = -1;
              this.formatVideoData();
            }
            await this.deleteFileAzure();
            this.videoIsDeleting = -1;
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.videoIsDeleting = -1;
            console.log(error);
            this.$message({
              type: "error",
              message: "Error occurred while trying to delete file!",
            });
          }
        })
        .catch(() => {
          this.videoIsDeleting = -1;
          this.$message({
            type: "info",
            message: "Delete Canceled",
          });
        });
    },

    generateVideoScriptsToArray() {
      this.arrayVideoScripts = [];
      const duration = this.$refs.videoPreview?.duration;
      if (!this.video.options.isShowSubtitles || !duration || !this.video.subtext) {
        return;
      }
      if (Math.floor(duration) <= this.timeChangeCurrentScript) {
        this.arrayVideoScripts.push(this.video.subtext);
        return;
      }
      const wordCount = this.video.subtext.split(" ");
      const wordInTimeScript = Math.ceil(
        (wordCount.length / duration) * this.timeChangeCurrentScript
      );
      const sentences = Math.floor(wordCount.length / wordInTimeScript);
      let count = 0;
      while (count <= sentences) {
        let next = count + 1;
        this.arrayVideoScripts.push(
          wordCount.slice(count * wordInTimeScript, next * wordInTimeScript).join(" ")
        );
        count++;
      }
    },
    updateCurrentScript(e) {
      const time = Math.floor(e.target.currentTime / this.timeChangeCurrentScript);
      if (time >= this.arrayVideoScripts.length) {
        this.currentSentences = time - 1;
        return;
      }
      if (this.currentSentences !== time) this.currentSentences = time;
    },
    inputVideoScripts() {
      this.video.text = this.video.subtext;
      this.generateVideoScriptsToArray();
    },

    toggleVideo() {
      this.isPlay = !this.isPlay;
      this.isPlay ? this.$refs.videoPreview.play() : this.$refs.videoPreview.pause();
    },
    handleErrorVideo() {
      if (this.timeLimitVideo <= 0) {
        this.loading = false;
        this.video.url = "";
        this.$message({
          showClose: true,
          message: "Oops..,Video is not response!. Can you try again?",
          type: "error",
        });
        return;
      }
      this.loading = true;
      this.timeLimitVideo -= this.timeReloadVideo;
      setTimeout(() => {
        this.handleReloadVideo();
      }, this.timeReloadVideo * 1000);
    },
    handleReloadVideo() {
      if (!this.loading) return;
      if (!this.$refs.videoPreview) {
        this.loading = false;
        return;
      }
      this.$refs.videoPreview.load();
    },
    handleSuccessVideo() {
      this.loading = false;
      this.isPlay = false;
      this.$refs.videoPreview?.pause();
      this.generateVideoScriptsToArray();
      this.$message({
        showClose: true,
        message: "Video processing/loading success!",
        type: "success",
      });
    },

    async deleteFileAzure() {
      const isFileAzure = this.video.url.includes("keyreplysandbox.blob.core.windows.net/files");
      if (!isFileAzure) return;
      const arrayUrl = this.video.url.split("/");
      const urlFile = arrayUrl.slice(4, arrayUrl.length - 1).join("/");
      const fileName = arrayUrl[arrayUrl.length - 1].split("?")[0];
      const filePath = `${urlFile}/${fileName}`;
      let form = new FormData();
      form.append("filename", filePath);
      await this.$rest("post", "deleteFile", form);
      this.video.url = "";
      this.$message({
        type: "success",
        message: "Delete successful",
      });
    },
    formatVideoData(type) {
      switch (type) {
        case "Generate Video":
          this.video.options.videoName = "";
          break;
        case "Upload":
          this.video.options.isShowSubtitles = false;
          this.video.subtext = "";
          this.video.options.template.title = "";
          this.video.options.template.templateId = "";
          this.video.options.template.description = "";
          this.video.options.template.visibility = "public";
          this.video.options.template.templateData.video_script = "";
          break;
        case "My Videos":
          this.video.options.videoName = "";
          this.video.options.template.title = "";
          this.video.options.template.templateId = "";
          this.video.options.template.description = "";
          this.video.options.template.visibility = "public";
          this.video.options.template.templateData.video_script = "";
          break;
      }
    },
  },
  apollo: {
    $subscribe: {
      editorSynthesiaURL: {
        query: gql`
          subscription {
            editorSynthesiaURL
          }
        `,
        result({ data }) {
          const url = _.get(data, "editorSynthesiaURL");
          this.loading = false;
          this.video.url = url;
        },
      },
    },
  },
  mounted() {
    const groupRadio = document.querySelectorAll(".el-radio-button__inner");
    for (let i = 0; i < groupRadio.length; i++) {
      groupRadio[i].style.width = "100%";
    }
  },
  async created() {
    await this.fetchListVideo();
    await this.fetchListTemplate();
  },
};
</script>

<style scoped lang="scss">
.video {
  .form {
    .header {
      .group {
        width: 100%;
        .item {
          width: calc(100% / 3);
        }
      }
    }

    .body {
      .el-form-item {
        margin-bottom: 10px;
        span {
          font-weight: bold;
          color: #000;
          font-size: 16px;
        }
      }
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        #localFileVideo {
          display: none;
        }
        .btn {
          display: flex;
          color: #fff;
          font-weight: bold;
          span {
            padding: 10px;
          }
          .icon {
            border-right: 1px solid rgb(255, 255, 255);
          }
        }
        .empty {
          background: #e4392b;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
        .success {
          background: #67c23a;
          margin-bottom: 10px;
        }
      }
      .carousel {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #000000;
        }
        li {
          list-style: none;
          flex-grow: 1;
          width: 33%;
        }
        .item {
          position: relative;
          video {
            display: block;
            width: 90%;
            border-radius: 5px;
            cursor: pointer;
          }
          video:hover + .icon-delete {
            display: inline-block;
          }
          .icon-delete {
            display: none;
            position: absolute;
            top: 1%;
            right: 11%;
            color: red;
            font-size: 24px;
            font-weight: bold;
            z-index: 99;
            &:hover {
              display: block;
            }
          }
          .selected {
            border: 3px solid red;
          }
          span {
            font-size: 13px;
          }
          margin-bottom: 10px;
        }
      }
    }
  }
  .preview {
    position: relative;
    color: #000;
    .container {
      display: flex;
      flex-direction: column;
      width: 410px;
      height: 640px;
      background-color: white;
      margin: 0 auto;
      box-shadow: 0 6px 12px rgb(71 69 123 / 24%), 0 2px 4px rgb(71 69 123 / 12%);
      border-radius: 10px 10px 0px 0px;
      .header {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 12px;
        border-bottom: 5px solid #e4392b;
        font-size: 17px;
        font-weight: 600;
      }
      .body {
        position: relative;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 14px;
        .video {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
        .scripts {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          max-height: 60px;
          width: 100%;
          background: #4e6cce;
          color: #fff;
          text-align: center;
          overflow: hidden;
          z-index: 11;
        }
        .btn-pause {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          background-size: cover;
          cursor: pointer;
          opacity: 0.85;
          transition: all 0.3s;
          transform: translate(-50%, -50%);
          z-index: 999;
          &:hover {
            width: 100px;
            height: 100px;
            opacity: 1;
          }
        }
        .buttons-overlay {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 60%;
          max-height: 40%;
          overflow-y: auto;
          transform: translateX(-50%);
          font-size: 16px;
          z-index: 1;
          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }
          &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #414649;
            border-radius: 10px;
          }
          .btn {
            display: flex;
            border-radius: 50px;
            margin-bottom: 10px;
            padding: 7px;
            align-items: center;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            .choice {
              text-align: center;
              background-color: #4e6cce;
              clip-path: circle();
              padding: 8px;
              margin-left: 5px;
            }
            .title {
              margin-left: 15px;
            }
          }
        }

        .chat-input-wrapper {
          position: absolute;
          bottom: 10px;
          width: 85%;
          display: flex;
          box-shadow: 0 0 3px #fff;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50px;
          z-index: 100;
          background-color: #f7f7f7;
          input {
            padding: 15px;
            font-size: 16px;
            border: 0;
            outline: 0;
            background-color: #f7f7f7;
            margin: 0;
            width: 85%;
            outline: none;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            &::placeholder {
              color: rgb(192, 192, 192);
            }
          }
          .btn-send {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            border: 1px transparent;
            cursor: pointer;
            color: #4e6cce;
            font-size: 25px;
            width: 15%;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
          }
        }
        .placeholder {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: #ddd;
        }
      }
    }
  }
}
</style>
