<template>
  <el-row :gutter="20" style="height: 100%; margin-bottom: 0" type="flex">
    <el-col style="height: 100%; max-width: 450px; min-width: 400px">
      <ChatSessionSummary @refreshSummary="refreshSummary" />
    </el-col>
    <el-col style="height: 100%">
      <AgentSessionDetails />
    </el-col>
  </el-row>
</template>

<script>
import { GRAPHQL_WEBSOCKET_STATE, listenGraphQLWebsocket } from "@/store/api";
import { notifyWebsocketReconnected } from "@/helperMethods/notifyError";
import { isFunction } from "@/helperMethods/util";
import ChatSessionSummary from "@/views/Supervisor/ChatSessionSummary";
import AgentSessionDetails from "@/views/Supervisor/AgentSessionDetails";

export default {
  data() {
    return {
      currentMode: "",
      selectedChatSession: null,
      currentExpanded: null,
    };
  },
  components: {
    ChatSessionSummary,
    AgentSessionDetails,
  },
  methods: {
    refreshSummary() {
      this.$store.dispatch("supervisor/FETCH_AGENTS_WITH_CHAT_COUNT", {
        email: this.$store.state.profile.email,
      });
      this.$store.dispatch("supervisor/SHOW_AGENT_LIST", { status: null });
      const isBusy = this.fetchQueueChatLoading || this.fetchMonitorChatLoading;
      if (!isBusy) {
        this.fetchChats();
      }
    },
    fetchChats() {
      this.fetchQueueChats();
      this.fetchResolvedChats();
    },
    fetchQueueChats() {
      this.$store.dispatch("FETCH_ALL_QUEUE_CHATS");
    },
    fetchResolvedChats() {
      this.$store.dispatch("FETCH_ALL_RESOLVED_CHATS_IN_TODAY");
    },
  },
  mounted() {
    this.fetchChats();
    this.unsubcribleWSReconnectedEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.RECONNECTED,
      () => {
        notifyWebsocketReconnected();
        this.fetchChats();
      }
    );
  },
  beforeDestroy() {
    isFunction(this.unsubcribleWSReconnectedEvent) && this.unsubcribleWSReconnectedEvent();
  },
};
</script>
