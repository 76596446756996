<template>
  <el-card style="height: 100%">
    <el-row type="flex" justify="space-between" align="middle">
      <el-col><h2 style="font-size: 22px">Chat Summary</h2></el-col>
      <el-col class="right-content">
        <div class="right-content--date">
          <i class="el-icon-date"></i> <span>{{ today }}</span>
        </div>
        <div class="right-content--refresh-btn">
          <el-tooltip placement="top">
            <div slot="content">Refresh summary</div>
            <el-button
              mini
              icon="el-icon-refresh"
              circle
              @click="$emit('refreshSummary')"
            ></el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <div class="summary-section-title">
      <a
        @click="$store.dispatch('supervisor/SHOW_AGENT_LIST', { status: null })"
        href="javascript:void(0)"
      >
        AGENTS-SUMMARY ({{ onlineAgentsCount + offlineAgentsCount }})
      </a>
    </div>
    <el-row type="flex" justify="center" align="middle">
      <el-col class="agent-statuses">
        <el-tooltip placement="top" content="Show list of online agents">
          <a
            class="agent-status agent-statuses--online"
            :class="{ active: showOnlineAgents }"
            @click="$store.dispatch('supervisor/SHOW_ONLINE_AGENTS')"
          >
            <div class="badge">{{ onlineAgentsCount }}</div>
            <span class="label">Online</span>
          </a>
        </el-tooltip>
        <el-tooltip placement="top" content="Show list of offline agents">
          <a
            class="agent-status agent-statuses--offline"
            :class="{ active: showOfflineAgents }"
            @click="$store.dispatch('supervisor/SHOW_OFFLINE_AGENTS')"
          >
            <div class="badge">{{ offlineAgentsCount }}</div>
            <span class="label">Offline</span>
          </a>
        </el-tooltip>
      </el-col>
    </el-row>
    <div class="summary-section-title">CHATS-SUMMARY ({{ totalChatsCount }})</div>
    <div style="display: flex; flex-direction: column; width: 100%; gap: 12px">
      <ChatGroupDropdown
        v-for="(listName, _index) in ['in-queue', 'on-going', 'abandoned', 'resolved']"
        :key="_index"
        :type="listName"
        :chats="getListByName(listName)"
        :chatsLoading="fetchQueueChatLoading"
        :expanded="currentExpanded === listName"
        @update:expanded="setExpanded"
        @update:selectedChat="setSelectedChat"
      />
    </div>
  </el-card>
</template>

<script>
import ChatGroupDropdown from "./ChatGroupDropdown";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    ChatGroupDropdown,
  },
  methods: {
    setExpanded(expandList) {
      this.$store.commit("supervisor/SET_CURRENT_EXPANDED", expandList);
    },
    setSelectedChat(chatSession) {
      if (chatSession) {
        this.$store.commit("supervisor/SET_SELECTED_CHAT_SESSION", chatSession);
      }
    },
    ...mapMutations("supervisor", [
      "SET_CURRENT_MODE",
      "SET_SELECTED_CHAT_SESSION",
      "SET_CURRENT_EXPANDED",
    ]),
    ...mapActions("supervisor", ["SHOW_AGENT_LIST", "SHOW_ONLINE_AGENTS", "SHOW_OFFLINE_AGENTS"]),
    getListByName(listName) {
      const listByNameMaps = {
        "in-queue": this.inQueueChatsArray,
        "on-going": this.onGoingChatsArray,
        abandoned: this.abandonedChatsArray,
        resolved: this.resolvedChatsArray,
      };

      return listByNameMaps[listName];
    },
  },
  watch: {
    currentExpanded(val) {
      if (val && this.currentMode) {
        this.currentMode = null;
      }
    },
  },
  created() {
    this.$store.dispatch("FETCH_ALL_RESOLVED_CHATS_IN_TODAY");
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DD");
    },
    ...mapState("supervisor", ["currentExpanded", "selectedChatSession", "currentMode"]),
    ...mapGetters([
      // reused from livechat store
      "fetchQueueChatLoading",
      "fetchResolvedChatLoading",
      "fetchAbandonedChatLoading",
      "resolvedChatsArray",
      "abandonedChatsArray",
    ]),
    ...mapGetters("supervisor", [
      "inQueueChatsArray",
      "onGoingChatsArray",
      "onlineAgentsCount",
      "offlineAgentsCount",
      "totalChatsCount",
      "showOnlineAgents",
      "showOfflineAgents",
      "agentsWithChatCount",
      "onlineAgentsWithChatCount",
      "offlineAgentsWithChatCount",
    ]),
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.right-content {
  display: flex;
  place-items: center;
  &--date {
    color: $color-dark;
    flex: 1;
    text-align: right;
    margin-right: 10px;
  }
  &--refresh-btn {
    float: right;
  }
}
.summary-section-title {
  color: $color-light;
  margin-bottom: 20px;
  cursor: default;
  text-decoration: underline;
}
.agent-statuses {
  place-items: center;
  place-content: center;
  gap: 10px;
  display: flex;
  .agent-status {
    font-size: 16px;
    float: right;
    display: flex;
    place-items: center;
    place-content: center;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    .label {
      margin-left: 5px;
    }
  }
  .badge {
    float: left;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 14px;
    place-items: center;
    place-content: center;
    display: flex;
  }
  &--online {
    .badge {
      background: $color-success;
      color: white;
    }
    color: $color-success;
    &:hover,
    &.active {
      border: 1px solid rgba(69, 183, 53, 0.25);
    }
  }
  &--offline {
    .badge {
      background: $color-danger;
      color: white;
    }
    color: $color-danger;
    &:hover,
    &.active {
      border: 1px solid rgb(229, 70, 57, 0.25);
    }
  }
}
</style>
