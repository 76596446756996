import { SupervisorState } from "./types";

const state: SupervisorState = {
  currentMode: "",
  selectedChatSession: null,
  currentExpanded: null,
  selectedAgent: null,
  agentsWithChatCount: [],
};

export default state;
