import Vue from "vue";
import { MutationTree } from "vuex";
import { SupervisorState } from "./types";
import { LivechatSessionType } from "@/store/modules/livechat/types";

const mutations: MutationTree<SupervisorState> = {
  SET_CURRENT_MODE(state, payload) {
    Vue.set(state, "currentMode", payload);
  },
  SET_SELECTED_CHAT_SESSION(state, payload: LivechatSessionType) {
    //TODO: reduce payload structure
    Vue.set(state, "selectedChatSession", payload);
  },
  SET_CURRENT_EXPANDED(state, payload) {
    if (state.currentMode && payload) {
      Vue.set(state, "currentMode", null);
    }
    Vue.set(state, "currentExpanded", payload);
  },
  SET_SELECTED_AGENT(state, { email }: { email: String }) {
    Vue.set(state, "selectedAgent", email);
  },
  SET_AGENTS_WITH_CHAT_COUNT(state, payload) {
    Vue.set(state, "agentsWithChatCount", payload);
  },
};

export default mutations;
